import React from 'react';
import * as S from './styles';

import { footerItems } from '../../utils/mocks/footer';

import AlliedBigLogo from '../../assets/allied-big-logo.png';
import Brasil from '../../assets/dashboard-icons/brazil.png';
import Pin from '../../assets/dashboard-icons/pin.png';
import DetailLeft from '../../assets/dashboard-icons/detail-left.png';
import DetailRight from '../../assets/dashboard-icons/detail-right.png';

import Facebook from '../../assets/facebook.png';
import Instagram from '../../assets/instagram.png';
import Linkedin from '../../assets/linkedin.png';

export const DashboardFooter: React.FC = () => {
  return (
    <>
      {/* Top Footer */}
      <S.ContainerFluid>
        <S.Image src={DetailLeft} alt="Decorator" className="left" />
        <S.Container>
          <S.Item>
            <S.TextBig>Vantagens em ser um revendedor Allied</S.TextBig>
          </S.Item>
          <S.Item>
            <S.Icon src={Brasil} alt="Brasil Icon" />
            <S.Text>
              Entrega Grátis <span> para todo o Brasil </span>
            </S.Text>
          </S.Item>
          <S.Item>
            <S.Icon src={Pin} alt="Pin Icon" />
            <S.Text>
              CDIs em diversas Regiões: <span> Consulte </span>
            </S.Text>
          </S.Item>
        </S.Container>
        <S.Image src={DetailRight} alt="Decorator" className="right" />
      </S.ContainerFluid>
      {/* Bottom Footer */}
      <S.ContainerFluidInfo>
        <S.ContainerInfo>
          <S.SocialInfo>
            <S.Icon src={AlliedBigLogo} alt="Allied Logo" />
            <S.IconWrapper>
              <a
                href="https://pt-br.facebook.com/alliedbrasil/"
                target="_blank"
                rel="noreferrer"
              >
                <S.SocialIcon src={Facebook} alt="Youtube Logo" />
              </a>
              <a
                href="https://www.instagram.com/alliedbrasil"
                target="_blank"
                rel="noreferrer"
              >
                <S.SocialIcon src={Instagram} alt="Instagram Logo" />
              </a>
              <a
                href="https://br.linkedin.com/company/alliedbrasil"
                target="_blank"
                rel="noreferrer"
              >
                <S.SocialIcon src={Linkedin} alt="Linkedin Logo" />
              </a>
            </S.IconWrapper>
          </S.SocialInfo>
          <S.ItemInfoContainer>
            {footerItems.map((element, index) => {
              return (
                <S.ItemInfo key={index}>
                  <S.TextSmall>{element.title}</S.TextSmall>
                  {element.items.map((item, index) => (
                    <S.Link key={index} href={item.link}>
                      {item.name}
                    </S.Link>
                  ))}
                </S.ItemInfo>
              );
            })}
          </S.ItemInfoContainer>
        </S.ContainerInfo>
      </S.ContainerFluidInfo>
      {/* Bottom Footer */}
      <S.ContainerFluidInfo>
        <S.ContainerInfo>
          <S.TextSmaller>
            Av. das Nações Unidas, 12.995 - 22º andar - Brooklin - CEP:
            04578-911 - São Paulo/SP <br /> ALLIED - Copyright © 2018 - Todos os
            direitos reservados
          </S.TextSmaller>
        </S.ContainerInfo>
      </S.ContainerFluidInfo>
    </>
  );
};
