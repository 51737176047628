import styled from 'styled-components';

export const LoadingAnimated = styled.img`
  width: 100px;
  margin: 0 auto;
`;

export const ContainerFluid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  min-height: 100px;
  width: 100%;
  padding: 38px;
  border-bottom: 1px solid #f2f2f2;

  @media (max-width: 960px) {
    padding: 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1380px;
  gap: 40px;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const ContainerMobile = styled.div`
  display: none;

  @media (max-width: 960px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

export const Image = styled.img``;

export const ImageHorizontal = styled.img`
  width: 100%;
  max-width: 350px;
`;

export const ImageMob = styled.img`
  width: 180px;
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;

  @media (max-width: 960px) {
    gap: 16px;
  }
`;

export const BoxUser = styled(Box)`
  gap: 8px;
`;

export const Text = styled.p`
  color: rgb(3, 59, 113);
  font-size: 14px;
  font-weight: 700;
`;

export const TextSmall = styled(Text)`
  font-size: 12px;
  margin: 0 0 16px;
`;

export const TextError = styled(Text)`
  color: red;
  font-size: 12px;
  margin: 0 0 16px;
`;

export const TextWrapper = styled.div`
  color: rgb(3, 59, 113);
  font-size: 14px;
  font-weight: 400;
  margin: 16px 0 0;

  text-align: start;
  width: 100%;
  max-width: 300px;

  display: flex;

  ul {
    li {
      margin-left: 16px;
      margin-top: 4px;
    }
  }
`;

export const Button = styled.button`
  font-weight: 700;
  border-radius: 50px;
  padding: 12px 29px;
  background: linear-gradient(90deg, #5f3075 0%, #a52868 100%);
  cursor: pointer;
  color: #fff;
  min-height: 42px;
  transition: ease 0.2s;

  &.out {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0;
    background: transparent;
    cursor: pointer;
    color: #a52868;
  }

  &:hover {
    opacity: 0.9;
  }
`;

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;

  &:hover .show {
    display: block;
  }
`;

export const DropdownButton = styled.button`
  background-color: transparent;
  padding: 16px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.2s ease;
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  border-radius: 12px;
  padding: 8px 0;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: 0.2s ease;
  left: 50%;
  transform: translate(-50%, 0%);

  &::after {
    content: '';
    position: absolute;
    z-index: 999;
    top: -5px;
    left: 50%;
    height: 0;
    width: 0;
    transform: translate(-50%, -50%);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid white;
    filter: drop-shadow(0px -3px 1px rgba(0, 0, 0, 0.2));
  }
`;

export const DropdownItem = styled.a`
  color: rgb(3, 59, 113);
  font-size: 14px;
  padding: 8px 24px;
  text-decoration: none;
  display: block;
  transition: 0.2s ease;

  &:hover {
    cursor: pointer;
    font-weight: 700;
    text-decoration: underline;
    text-decoration-color: #f7941d;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #e0e0e0;
  height: 96px;
  width: 100%;
  padding: 24px;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  gap: 32px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  background-color: #f2f2f2;
  color: #7c7e80;
  padding: 22px 17px;
  width: 300px;
  height: 48px;
  border-radius: 80px;
  font-size: 12px;
  border: 0;
  margin: 8px 0px 8px;
`;

export const ButtonWithLoading = styled.button`
  background-color: #f7941d;
  width: 300px;
  height: 48px;
  border-radius: 80px;
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  margin: 24px 0 0;
  cursor: pointer;

  &.loading {
    opacity: 0.8;
    pointer-events: none;
  }
`;

export const Link = styled.a`
  color: #033b71;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-decoration: underline;
`;

export const ErrorText = styled.span`
  text-align: left;
  color: red;
  font-size: 12px;
  align-self: flex-start;
`;

export const PasswordWraper = styled.div`
  position: relative;
`;

export const PasswordControl = styled.a`
  position: absolute;
  right: 22px;
  top: 22px;
  cursor: pointer;
`;

export const Title = styled.h3`
  color: rgb(3, 59, 113);
`;
