import styled from 'styled-components';

export const Container = styled.section`
  @media (min-width: 320px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 100px auto 50px;
  }

  @media (max-width: 1366px) {
    margin: 80px auto 50px;
  }
`;

export const Image = styled.img`
  width: 284px;
  margin: 0 0 40px;
`;

export const Text = styled.span`
  color: #231f20;
  line-height: 19.07px;
  text-align: center;
  font-weight: 400;
  margin: 0 0 32px;

  @media (min-width: 320px) {
    font-size: 12px;
  }

  @media (min-width: 375px) {
    font-size: 14px;
  }
`;

export const TextBold = styled.span`
  color: #231f20;
  text-align: center;
  font-weight: 700;
  font-size: 13px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  background-color: #f2f2f2;
  color: #7c7e80;
  padding: 22px 17px;
  width: 304px;
  height: 48px;
  border-radius: 80px;
  font-size: 12px;
  border: 0;
  margin: 8px 0px;
`;

export const Button = styled.button`
  background-color: #f7941d;
  width: 304px;
  height: 48px;
  border-radius: 80px;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  margin: 24px 0px;
  cursor: pointer;

  &.loading {
    opacity: 0.8;
    pointer-events: none;
  }
`;

export const LinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Link = styled.a`
  color: #033b71;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-decoration: underline;
`;

export const LinkBold = styled.a`
  color: #f7941d;
  font-size: 13px;
  font-weight: 700;
  text-decoration: underline;
`;

export const ErrorText = styled.span`
  text-align: left;
  color: red;
  font-size: 12px;
  align-self: flex-start;
`;

export const PasswordWraper = styled.div`
  position: relative;
`;

export const PasswordControl = styled.a`
  position: absolute;
  right: 22px;
  top: 22px;
  cursor: pointer;
`;

export const AccessWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;
