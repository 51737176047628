import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { useOrder, useStorage } from '../../hooks';
import { useParams } from 'react-router-dom';
import {
  Topbar,
  Navbar,
  DashboardFooter,
  Breadcrumb,
  OrderProgressBar,
} from '../../components';

import Billet from '../../assets/order-icons/billet.png';
import Iphone from '../../assets/order-icons/iphone.png';
import {
  formatDate,
  formatValue,
  removeLastZerosAndFormat,
} from '../../utils/functions';

export const OrderDetail: React.FC = () => {
  const { id } = useParams();
  const { getItemOnStorage } = useStorage();
  const { isLoading, getOrderDetails, getOrderStatus } = useOrder();
  const [orderDetails, setOrderDetails] = useState<any>();
  const [error, setError] = useState<any>(null);
  const [order, setorder] = useState({
    number: '',
    date: '',
    cnpj: '',
    clientName: '',
    address: '',
    payment: '',
    valueFull: '',
    valueFinal: '',
    valueShipping: '',
    status: '',
    orderHistory: [
      {
        label: '',
        labelOrigin: '',
        dateTime: '',
      },
    ],
  });

  const todayDate = new Date().toISOString().split('T')[0];

  const callOrderDetails = async () => {
    const response = await getOrderDetails({
      term: id,
      type: 'numPedAllied',
      dateStart: formatDate('2020-01-01'),
      dateEnd: formatDate(todayDate),
    });
    const statusResponse = await getOrderStatus({
      orderNumber: id,
      cnpjNumber: response[0].sClienteCNPJ,
    });
    const orderStatus = statusResponse.data.d.map(
      (element: {
        sOcorrencia: string;
        sOcorrenciaStatus: string;
        sOcorrenciaData: string;
      }) => {
        return {
          label: element.sOcorrenciaStatus,
          labelOrigin: element.sOcorrencia,
          dateTime: element.sOcorrenciaData,
        };
      },
    );

    if (response.errors) {
      setError(response.errors[0]);
    }

    setOrderDetails(response[0]);
    setorder({
      number: response[0].sPedidoNumero,
      date: response[0].sPedidoDataPedido,
      cnpj: response[0].sClienteCNPJ.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5',
      ),
      clientName: response[0].sClienteNome,
      address: `${getItemOnStorage('address')} ${response[0].sDestinoCidade} ${
        response[0].sDestinoUF
      } - CEP ${response[0].sDestinoCEP
        .replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1')}`,
      payment: response[0].sCondicaoPagamento,
      valueFull: response[0].sPedidoItemValorUnitario,
      valueFinal: response[0].sPedidoItemValorTotal,
      valueShipping: response[0].sPedidoFreteCotado,
      status: response[0].sPedidoStatus,
      orderHistory: orderStatus,
    });
  };

  useEffect(() => {
    callOrderDetails();
  }, []);

  const checkOrderStatus =
    order?.status === 'Cancelado' || order?.status === 'Reprovado';

  return (
    <S.Container>
      <Topbar />
      <Navbar />
      <S.Content>
        <Breadcrumb paths={paths} />
        <S.Title>Detalhes do Pedido</S.Title>

        <S.TextError>{error}</S.TextError>

        {isLoading || error ? (
          <p>{isLoading && 'Carregando'}</p>
        ) : (
          <S.DetailContainer>
            <S.TextFeatured>Pedido #{order?.number}</S.TextFeatured>
            <S.Text>Realizado em {order?.date.slice(0, 11)}</S.Text>
            {checkOrderStatus && (
              <S.TextError>Status: {order?.status}</S.TextError>
            )}
            {!checkOrderStatus && <S.Text>Status: {order?.status}</S.Text>}

            <OrderProgressBar orderHistory={order.orderHistory} />

            <S.OrderInfo>
              <S.Delivery>
                <S.Text>
                  <span>Entrega</span>
                </S.Text>
                <S.Text className={'font-medium'}>
                  Cliente {order?.clientName} <br />
                  CNPJ {order?.cnpj}
                </S.Text>
                <S.Text>{order.address}</S.Text>
              </S.Delivery>

              <S.Summary>
                <S.Text>
                  <span>Resumo do Pedido</span>
                </S.Text>
                <S.SummaryItem>
                  <S.Text>Subtotal dos itens</S.Text>
                  <S.Text>
                    {/* R$ {removeLastZerosAndFormat(order?.valueFull)} */}
                    R$ {formatValue(order?.valueFinal)}
                  </S.Text>
                </S.SummaryItem>
                {/* <S.SummaryItem>
                  <S.Text>Desconto</S.Text>
                  <S.Text>R$ 0,00</S.Text>
                </S.SummaryItem> */}
                <S.SummaryItem>
                  <S.Text>Frete</S.Text>
                  <S.Text>R$ {'0,00'}</S.Text>
                </S.SummaryItem>
                <S.SummaryTotal>
                  <S.Text>
                    <span>Total</span>
                  </S.Text>
                  <S.Text>
                    <span>R$ {formatValue(order?.valueFinal)}</span>
                  </S.Text>
                </S.SummaryTotal>
              </S.Summary>

              <S.Payment>
                <S.Text>
                  <span>Pagamento</span>
                </S.Text>
                <S.PaymentType>
                  <S.Icon src={Billet} />
                  <S.Text>{order?.payment}</S.Text>
                </S.PaymentType>
                <S.Text>
                  <span>Número do Pedido Allied</span>
                </S.Text>
                <S.Text>#{order?.number}</S.Text>
              </S.Payment>
            </S.OrderInfo>

            <S.OrderSummary>
              <S.Top>
                <S.Text>
                  <span>Resumo de Itens</span>
                </S.Text>
              </S.Top>

              <S.Bottom>
                <S.OrderItem>
                  <S.ItemDescription>
                    {/* <S.Icon src={Iphone} /> */}
                    <S.TextWrapper>
                      <S.Text>
                        <span>{orderDetails?.sProdutoDescricao}</span>
                      </S.Text>
                      <S.Text className="font-small">
                        {parseInt(orderDetails?.sPedidoItemQtde)} unidade(s)
                      </S.Text>
                    </S.TextWrapper>
                  </S.ItemDescription>

                  <S.ItemPrice>
                    <S.Text>
                      <span>
                        R$
                        {removeLastZerosAndFormat(order?.valueFull)}
                      </span>
                    </S.Text>
                  </S.ItemPrice>
                </S.OrderItem>

                {/* <S.ButtonWrapper>
                  <Button title="Acompanhar envio" />
                  <Button title="Ver Nota Fiscal" type="secondary" />
                  <Button title="Preciso de Ajuda" type="transparent" />
                </S.ButtonWrapper> */}
              </S.Bottom>
            </S.OrderSummary>
          </S.DetailContainer>
        )}
      </S.Content>
      <DashboardFooter />
    </S.Container>
  );
};

const paths = [
  {
    title: 'Meu Painel',
    link: '/painel',
  },
  {
    title: 'Consulta de Pedidos',
    link: '/pedidos',
  },
  {
    title: 'Detalhes do Pedido',
    link: '/',
  },
];
