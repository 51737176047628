import * as S from './styles';
import React from 'react';

interface IPaginationProps {
  currentPage: number;
  paginationSize: number;
  paginationSearch: (arg0: number) => void;
  totalPages: number;
  startWithZero: boolean;
}

export const Pagination: React.FC<IPaginationProps> = ({
  currentPage,
  paginationSearch,
  paginationSize,
  totalPages,
  startWithZero,
}) => {
  const startPage = startWithZero ? 0 : 1;
  const endPage = startWithZero ? totalPages - 1 : totalPages;
  const actualCurrentPage = startWithZero ? currentPage + 1 : currentPage;

  return (
    <S.PaginationContainer>
      <S.Pagination>
        <S.Text>Itens por página: {paginationSize}</S.Text>
        {totalPages > 0 && (
          <S.Text>
            {actualCurrentPage} de {totalPages}
          </S.Text>
        )}
        <S.PagPrevious
          className={currentPage === startPage ? 'is-disabled' : ''}
          disabled={currentPage === startPage}
          onClick={() => {
            paginationSearch(startPage);
          }}
        >
          {'|<'}
        </S.PagPrevious>
        <S.PagPrevious
          className={currentPage === startPage ? 'is-disabled' : ''}
          disabled={currentPage === startPage}
          onClick={() => paginationSearch(currentPage - 1)}
        >
          {'<'}
        </S.PagPrevious>
        <S.PagNext
          className={
            currentPage === endPage || totalPages === 0 ? 'is-disabled' : ''
          }
          disabled={currentPage === endPage || totalPages === 0}
          onClick={() => paginationSearch(currentPage + 1)}
        >
          {'>'}
        </S.PagNext>
        <S.PagNext
          className={
            currentPage === endPage || totalPages === 0 ? 'is-disabled' : ''
          }
          disabled={currentPage === endPage || totalPages === 0}
          onClick={() => paginationSearch(endPage)}
        >
          {'>|'}
        </S.PagNext>
      </S.Pagination>
    </S.PaginationContainer>
  );
};
