import styled from 'styled-components';

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 17px;
    text-align: left;
    white-space: nowrap;
    border-bottom: 3px solid white;
  }

  th {
    font-size: 13px;
    color: #202020;
    background-color: white;
    font-weight: 600;
  }

  tr {
    font-size: 14px;
    color: #525252;
    font-weight: 500;
    background-color: #f5f5f5;
  }

  tr:hover {
    font-size: 14px;
    color: #222;
    background-color: #f7941d1a;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    th,
    td {
      padding: 8px;
    }
  }
`;
