import React, { useEffect, useState } from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as S from './styles';
import {
  LogListItem,
  ModalResponsive,
  Loading,
  Pagination,
  DynamicFilter,
} from '../../components';
import Details from '../../assets/buttons/details.svg';
import { useLogs } from '../../hooks';
import { truncate } from '../../utils/functions';

import {
  IDynamicFilterData,
  IDynamicFilterSelectOptions,
} from '../../interfaces';

export const Logs: React.FC = () => {
  const {
    returnAllLogs,
    returnSingleLog,
    isLoading,
    metadata,
    logs,
    singleLog,
    isLoadingSingleLog,
  } = useLogs();

  const [filters, setFilters] = useState<IDynamicFilterData>({
    type: 'contexto',
    term: '',
    dateStart: '2023-01-01',
    dateEnd: '2023-12-31',
  });

  const [OpenDetailsModal, setOpenDetailsModal] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    size: 20,
  });

  const totalPages = Math.ceil(metadata.total / pagination.size);

  useEffect(() => {
    returnAllLogs({
      type: filters.type,
      term: filters.term,
      currentPage: pagination.current,
      pageSize: pagination.size,
      dateStart: filters.dateStart,
      dateEnd: filters.dateEnd,
    });
  }, [filters, pagination.current]);

  const paginationSearch = (index: number) => {
    if (index > 0 && index <= totalPages) {
      window.scrollTo(0, 0);
      setPagination({
        current: index,
        size: pagination.size,
      });
      returnAllLogs({
        type: filters.type,
        term: filters.term,
        currentPage: index,
        pageSize: pagination.size,
        dateStart: filters.dateStart,
        dateEnd: filters.dateEnd,
      });
    }
  };

  const searchFilters = (formData: IDynamicFilterData) => {
    setFilters(formData);
    returnAllLogs({
      type: formData.type,
      term: formData.term,
      currentPage: 1,
      pageSize: pagination.size,
      dateStart: formData.dateStart,
      dateEnd: formData.dateEnd,
    });
  };

  const handleOpenDetails = (id: string) => {
    setOpenDetailsModal(true);
    returnSingleLog({ id });
  };

  const filterSelectOptions: IDynamicFilterSelectOptions[] = [
    { value: 'contexto', description: 'Contexto' },
    { value: 'ean', description: 'EAN' },
  ];

  return (
    <S.Container>
      <S.Content>
        <DynamicFilter
          filterSelectOptions={filterSelectOptions}
          filters={filters}
          calendar={true}
          isLoading={isLoading}
          searchFilters={searchFilters}
          setFilters={setFilters}
        />

        <S.Tabs.TabsContainer>
          {isLoading && <Loading />}
          {!isLoading && (
            <S.Tabs.TabHeader>
              <S.Tabs.TabCell>Contexto (PriceTableId)</S.Tabs.TabCell>
              <S.Tabs.TabCell className="smaller">EAN</S.Tabs.TabCell>
              <S.Tabs.TabCell>Data de Registro</S.Tabs.TabCell>
              <S.Tabs.TabCell className="expanded-lg">
                Tipo de Erro
              </S.Tabs.TabCell>
              <S.Tabs.TabCell>Informações Adicionais</S.Tabs.TabCell>
            </S.Tabs.TabHeader>
          )}
          {logs.map((elm: any) => (
            <S.Tabs.TabBody key={elm.id}>
              <S.Tabs.TabCell>{elm.contexto ?? '-'}</S.Tabs.TabCell>
              <S.Tabs.TabCell className="smaller">
                {elm.ean ?? '-'}
              </S.Tabs.TabCell>
              <S.Tabs.TabCell>
                {elm.dataRegistro.replace(' ', ' às ') ?? '-'}
              </S.Tabs.TabCell>
              <S.Tabs.TabCell>{elm.dataRegistro ?? '-'}</S.Tabs.TabCell>
              <S.Tabs.TabCell className="expanded-lg">
                {elm.error && truncate(elm.error ?? '-', 86)}
              </S.Tabs.TabCell>
              <S.Tabs.TabCell>
                <S.IconButton
                  className={'edit'}
                  onClick={() => handleOpenDetails(elm.id)}
                >
                  <img src={Details} width={16} />
                  Detalhes
                </S.IconButton>
              </S.Tabs.TabCell>
            </S.Tabs.TabBody>
          ))}
        </S.Tabs.TabsContainer>

        <S.ListContainer>
          {isLoading && <Loading />}
          {logs.map((elm: any) => (
            <LogListItem key={elm.id} log={elm} type={'pricetable'}>
              <S.IconButton
                className={'edit'}
                onClick={() => handleOpenDetails(elm.id)}
              >
                <img src={Details} width={16} />
                Detalhes
              </S.IconButton>
            </LogListItem>
          ))}
        </S.ListContainer>

        {!isLoading && logs.length === 0 && (
          <S.Message>Nenhum resultado encontrado.</S.Message>
        )}

        {!isLoading && logs.length !== 0 && (
          <Pagination
            startWithZero={false}
            currentPage={pagination.current}
            paginationSize={pagination.size}
            paginationSearch={paginationSearch}
            totalPages={totalPages}
          />
        )}
      </S.Content>

      <ModalResponsive
        title={'Detalhes de Log'}
        isOpenModal={OpenDetailsModal}
        handleOpenModal={setOpenDetailsModal}
      >
        <S.Featured>
          {isLoadingSingleLog ? (
            <Loading />
          ) : (
            singleLog.log && (
              <S.Text className="large">{singleLog.log.error}.</S.Text>
            )
          )}
        </S.Featured>
        <S.Buttons>
          <S.IconButton
            className="close"
            onClick={() => setOpenDetailsModal(false)}
          >
            Fechar
          </S.IconButton>
        </S.Buttons>
      </ModalResponsive>

      <ToastContainer
        closeButton={false}
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </S.Container>
  );
};
