import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    margin:0;
    padding:0;
    overflow-x:hidden;
  }

  body {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
  }

  a {
    text-decoration: none;
  }

  button {
    border: 0;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .react-responsive-modal-modal {
    padding: 0;
    width: 100%;
    max-width: 620px;
  }

  .sweetPagination {  

    .paginationUL {      

      .pageItem {

        .pageButton {
          transition: 0.2s ease;
          background: transparent;
          font-size: 14px;
          font-weight: normal;
          color: #58595b;

          .navigationIcon {
            color: #58595b;
            width: 7px;
          }

          .dottedIcon {
            color: #58595b;
            width: 14px;
          }
        }

        .activeButton {
          color: #f7941d;
          font-weight: bold;
        }
      }
    }
  }
  
.Toastify__toast-container {
  width: 100%;
  max-width: 400px;
}

.Toastify__toast-theme--colored.Toastify__toast--default {
  background-color: #D4EDDA;
  color: black;
  border-radius: 16px;
  font-size: 14px;
}

.customModal {
  border-radius: 16px;
}

.small-modal {
  max-width: 400px;
}

.react-responsive-modal-closeButton {
  padding: 6px 8px;
}

.react-responsive-modal-modal {
  @media (max-width: 768px) {
    width: 100%;
    max-width: 335px;
  }
}
  
.ReactTags__tags {
  position: relative;
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  &.secondary {
    background-color: #fff;
    border: 1px solid black !important;
    padding: 15px 15px;
    width: 100%;
    height: auto;
    min-height: 48px;
    border-radius: 16px;
    font-size: 12px;
    margin: 8px 0px;
    &:focus {
      border: 1px solid black;
    }
  }
}

/* Styles for the input */
.ReactTags__tagInput {
  width: 100%;
  border-radius: 2px;
  display: inline-block;
  min-width: 500px;
}

@media (max-width: 769px) {
  .ReactTags__tagInput {
    min-width: 230px;
  }
}


.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  outline: none;
  width: 100%;
  color: black;
  border: none;

}

.ReactTags__selected {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #EAEAEA;
  color: black;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  border-radius: 80px;
}
.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: black;
  margin-left: 5px;
}

`;
