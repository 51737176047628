import styled from 'styled-components';

export const TitleContent = styled.h1`
  font-size: 32px;
  margin-bottom: 24px;
  color: rgb(3, 59, 113);

  @media (max-width: 1024px) {
    font-size: 18px;
  }
`;
