import axios from 'axios';

export const identityProvider = axios.create({
  baseURL: `${process.env.REACT_APP_KEY_IDENTITY_PROVIDER}`,
});

export const dealerProvider = axios.create({
  baseURL: `${process.env.REACT_APP_KEY_DEALER_PROVIDER}`,
});

export const clientProvider = axios.create({
  baseURL: `${process.env.REACT_APP_KEY_CLIENT_PROVIDER}`,
});

export const orderProvider = axios.create({
  baseURL: `${process.env.REACT_APP_KEY_ORDER_PROVIDER}`,
});

export const danfeProvider = axios.create({
  baseURL: `${process.env.REACT_APP_KEY_DANFE_PROVIDER}`,
});

export const boletosProvider = axios.create({
  baseURL: `${process.env.REACT_APP_KEY_BOLETOS_PROVIDER}`,
});

export const logsProvider = axios.create({
  baseURL: `${process.env.REACT_APP_KEY_LOGS_PROVIDER}`,
});

export const commissioningProvider = axios.create({
  baseURL: `${process.env.REACT_APP_KEY_COMMISSIONING_PROVIDER}`,
});
