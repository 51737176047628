import React from 'react';
import * as S from './styles';

import AlliedLogo from '../../assets/allied-logo.png';

import Instagram from '../../assets/instagram.png';
import Linkedin from '../../assets/linkedin.png';
import Facebook from '../../assets/facebook.png';

export const LoginFooter: React.FC = () => {
  return (
    <S.ContainerFluid>
      <S.Container>
        <S.InfoWrapper>
          <S.Image src={AlliedLogo} alt="Allied Logo" />
          <S.TextWrapper>
            <S.Text>
              ALLIED - Copyright © 2018 - Todos os direitos reservados
            </S.Text>
            <S.Text>
              Av. das Nações Unidas, 12.995 - 22º andar - Brooklin - CEP:
              04578-911 - São Paulo/SP
            </S.Text>
          </S.TextWrapper>
        </S.InfoWrapper>
        <S.InfoWrapper>
          <S.Text> Acompanhe a Allied nas redes sociais </S.Text>
          <S.IconWrapper>
            <a
              href="https://pt-br.facebook.com/alliedbrasil/"
              target="_blank"
              rel="noreferrer"
            >
              <S.Image src={Facebook} alt="Youtube Logo" />
            </a>
            <a
              href="https://www.instagram.com/alliedbrasil"
              target="_blank"
              rel="noreferrer"
            >
              <S.Image src={Instagram} alt="Instagram Logo" />
            </a>
            <a
              href="https://br.linkedin.com/company/alliedbrasil"
              target="_blank"
              rel="noreferrer"
            >
              <S.Image src={Linkedin} alt="Linkedin Logo" />
            </a>
          </S.IconWrapper>
        </S.InfoWrapper>
      </S.Container>
    </S.ContainerFluid>
  );
};
