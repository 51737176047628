import React, { useState } from 'react';
import * as S from './styles';
import { formatValue } from '../../utils/functions';

import ArrowDown from '../../assets/arrow-down-order.png';
import { IOrder } from '../../interfaces';
import { useStorage } from '../../hooks';

export const OrderCard: React.FC<IOrder> = ({ order }) => {
  const [isActive, setIsActive] = useState(false);
  const { setItemOnStorage } = useStorage();

  const setAddess = () => {
    setItemOnStorage('address', order.sPedidoEntregaEndereco);
    setItemOnStorage('orderNumber', order.sPedidoNumero);
  };

  return (
    <S.Container className={isActive ? 'active' : ''}>
      {/* Informação no topo do Card */}
      <S.Top onClick={() => setIsActive(!isActive)}>
        <S.Cell>
          <S.Text>
            <span>Pedido E-Commerce</span>
          </S.Text>
          <S.Text>#{order.sPedidoCliente || '-'}</S.Text>
        </S.Cell>
        <S.Cell />
        <S.Cell />
        <S.Cell>
          <S.Text>
            <span>Data do pedido</span>
          </S.Text>
          <S.Text>{order.sPedidoDataPedido.slice(0, 10) || '-'}</S.Text>
        </S.Cell>
        <S.Cell>
          <S.Text className={'small'}>
            Clique aqui para acompanhar o status do pedido
          </S.Text>
        </S.Cell>
        <S.ArrowDownMobile
          src={ArrowDown}
          className={isActive ? 'active' : ''}
        />
      </S.Top>
      {/* Informação no corpo do Card */}
      <S.Bottom>
        <S.BlockCell>
          <S.Text className="big">Cliente {order.sClienteNome}</S.Text>
        </S.BlockCell>
        <S.BottomWrapper className={isActive ? 'active' : ''}>
          <S.Cell>
            <S.Text>
              <span>CNPJ</span>
            </S.Text>
            <S.Text>
              {order.sClienteCNPJ.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                '$1.$2.$3/$4-$5',
              ) || '-'}
            </S.Text>
          </S.Cell>
          <S.Cell>
            <S.Text>
              <span>Nota Fiscal</span>
            </S.Text>
            <S.Text>{order.sNotaNumero || '-'}</S.Text>
          </S.Cell>
          <S.Cell>
            <S.Text>
              <span>Emissão da Nota Fiscal</span>
            </S.Text>
            <S.Text>{order.sNotaDataEmissao.slice(0, 10) || '-'}</S.Text>
          </S.Cell>
          <S.Cell>
            <S.Text>
              <span>Valor total do pedido</span>
            </S.Text>
            <S.Text>R$ {formatValue(order.sPedidoValorTotal) || '-'}</S.Text>
          </S.Cell>
          <S.Cell>
            <S.Text>
              <span>Pedido Allied</span>
            </S.Text>
            <S.Text>{order.sPedidoNumero || '-'}</S.Text>
          </S.Cell>
        </S.BottomWrapper>
        <S.BlockCell>
          <S.LinkMobile
            href={`/pedido/${order.sPedidoNumero}`}
            onClick={setAddess}
          >
            <S.ButtonMobile className={isActive ? 'active' : ''}>
              Detalhes do Pedido
            </S.ButtonMobile>
          </S.LinkMobile>
        </S.BlockCell>
      </S.Bottom>
      <S.LinkDesktop
        href={`/pedido/${order.sPedidoNumero}`}
        onClick={setAddess}
      />
    </S.Container>
  );
};
