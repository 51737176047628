import React, { useState } from 'react';
import * as S from './styles';
import { formatValue } from '../../utils/functions';

import ArrowDown from '../../assets/arrow-down-order.png';
import { IOrder } from '../../interfaces';
import { Button } from '../button';
import { useDanfe, useStorage } from '../../hooks';

export const OrderCardDanfe: React.FC<IOrder> = ({ order, type, term }) => {
  const [isActive, setIsActive] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [email, setEmail] = useState<string>('');

  const { getItemOnStorage } = useStorage();
  const { apiState, sendDanfeByEmail } = useDanfe();
  const currentUser = getItemOnStorage('currentUser');
  const user = JSON.parse(currentUser || '');
  const vendorEmail = user?.userEmail || '';

  const sendDanfe = async (emailToSend: string | undefined) => {
    if (emailToSend) {
      await sendDanfeByEmail({
        email: emailToSend,
        notaNumero: order.sNotaNumero,
        tipoNfe: order.sPedidoTipo,
        empresaNumero: order.sEmpresaNumero,
        type: type,
        term: term,
      });
    }

    setTimeout(() => {
      setIsSent(false);
    }, 3000);
  };

  return (
    <S.Container className={isActive ? 'active' : ''}>
      {/* Informação no topo do Card */}
      <S.Top onClick={() => setIsActive(!isActive)}>
        <S.Cell className={'wide'}>
          <S.Text>
            <span>Pedido E-Commerce</span>
          </S.Text>
          <S.Text>#{order.sPedidoCliente || '-'}</S.Text>
        </S.Cell>
        <S.Cell>
          <S.Text>
            <span>Data do pedido</span>
          </S.Text>
          <S.Text>{order.sPedidoDataPedido.slice(0, 10) || '-'}</S.Text>
        </S.Cell>
        <S.ArrowDownMobile
          src={ArrowDown}
          className={isActive ? 'active' : ''}
        />
      </S.Top>
      {/* Informação no corpo do Card */}
      <S.Bottom onClick={() => setIsActive(!isActive)}>
        <S.BlockCell>
          <S.Text className="big">Cliente {order.sClienteNome}</S.Text>
        </S.BlockCell>
        <S.BottomWrapper className={isActive ? 'active' : ''}>
          <S.Cell>
            <S.Text>
              <span>CNPJ</span>
            </S.Text>
            <S.Text>
              {order.sClienteCNPJ.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                '$1.$2.$3/$4-$5',
              ) || '-'}
            </S.Text>
          </S.Cell>
          <S.Cell>
            <S.Text>
              <span>Nota Fiscal</span>
            </S.Text>
            <S.Text>{order.sNotaNumero || '-'}</S.Text>
          </S.Cell>
          <S.Cell>
            <S.Text>
              <span>Emissão da Nota Fiscal</span>
            </S.Text>
            <S.Text>{order.sNotaDataEmissao.slice(0, 10) || '-'}</S.Text>
          </S.Cell>
          <S.Cell>
            <S.Text>
              <span>Valor total do pedido</span>
            </S.Text>
            <S.Text>R$ {formatValue(order.sPedidoValorTotal) || '-'}</S.Text>
          </S.Cell>
          <S.Cell>
            <S.Text>
              <span>Pedido Allied</span>
            </S.Text>
            <S.Text>{order.sPedidoNumero || '-'}</S.Text>
          </S.Cell>
        </S.BottomWrapper>
      </S.Bottom>
      {/* Informação no corpo do Card */}
      <S.SendOptions className={isActive ? 'active' : ''}>
        {!order?.sClienteEmail ? (
          <S.SendForm>
            <S.Text>
              <span>E-mail de revendedor não cadastrado.</span>
            </S.Text>
            <S.Text>
              <span>
                Cadastre um email de revendedor para receber a segunda via
                Danfe.
              </span>
            </S.Text>
          </S.SendForm>
        ) : (
          <S.SendForm>
            <S.Text>
              <span>
                E-mails de envio cadastrados para Revenda e Cliente Final:
              </span>
            </S.Text>
            <S.InputWrapper>
              {apiState.isLoading && (
                <S.SendingMessage>Enviando...</S.SendingMessage>
              )}
              {apiState.data && (
                <S.SentMessage className={'success'}>
                  Email enviado
                </S.SentMessage>
              )}
              {apiState.error && (
                <S.SentMessage className={'error'}>
                  Erro ao enviar
                </S.SentMessage>
              )}
              {apiState.multi && (
                <S.SentMessage className={'success'}>
                  Verifique sua caixa de entrada.
                </S.SentMessage>
              )}
              <S.Input
                value={vendorEmail + ', ' + order?.sClienteEmail}
                disabled
                placeholder="Digite um email"
                type="email"
                required
              ></S.Input>
              <S.ButtonWrapper
                onClick={() => {
                  sendDanfe(order?.sClienteEmail);
                  setIsSent(!isSent);
                }}
              >
                <Button title="Enviar DANFE" type={isSent ? 'inactive' : ''} />
              </S.ButtonWrapper>
            </S.InputWrapper>
          </S.SendForm>
        )}
        <S.OrderDetails>
          <S.ButtonWrapper href={`/pedido/${order.sPedidoNumero}`}>
            <Button title="Detalhes do Pedido" type="secondary" />
          </S.ButtonWrapper>
        </S.OrderDetails>
      </S.SendOptions>
    </S.Container>
  );
};
