import moment from 'moment';
import { toast } from 'react-toastify';

export function formatDate(date: string) {
  const dateSliced = date.split('-');
  const day = dateSliced[2];
  const month = dateSliced[1];
  const year = dateSliced[0];
  return day + '/' + month + '/' + year;
}

export function formatValue(value: string) {
  if (value.length > 6) {
    const firstDigits = value.slice(0, -6);
    const lastDigits = value.substring(value.length - 6);
    const formattedValue = firstDigits + '.' + lastDigits;
    return formattedValue;
  }
  return value;
}

export function removeLastZerosAndFormat(value: string) {
  const formattedValue = value.substring(0, value.length - 7);
  return formatValue(formattedValue);
}

export function limitString(str: string) {
  const maxLength = 30;
  const suffix = '...';

  if (str.length <= maxLength) {
    return str;
  } else {
    return str.slice(0, maxLength - suffix.length) + suffix;
  }
}

export function compareDateWithToday(data1: string | null) {
  const formato = 'DD/MM/YYYY';
  const todayDate = new Date().toISOString().split('T')[0];

  const data1Obj = moment(data1, formato);
  const data2Obj = moment(formatDate(todayDate), formato);

  if (data1Obj.isAfter(data2Obj)) {
    return 'maior';
  } else {
    return 'menor';
  }
}

export function removerZeros(value: string) {
  const result = value.toString();
  if (result.length === 1) {
    return '0' + result;
  } else {
    return result;
  }
}

export function formatValueInReal(value: string) {
  const valueWithoutComma = value.replace(',', '.');

  const numericValue = parseFloat(valueWithoutComma);

  if (isNaN(numericValue)) {
    return 'Valor inválido';
  }

  const result = numericValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return result;
}

export function formatCoin(moeda: string) {
  const value = parseFloat(moeda.replace(',', '.'));

  if (isNaN(value)) {
    return 'Valor inválido.';
  }

  const format = {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  };

  const result = value.toLocaleString('pt-BR', format);

  return result;
}

export const calcSumValues = (receivedData: any) => {
  const sumValues: any = {};

  receivedData.forEach((item: any) => {
    const clienteNome = item.sClienteNome;
    const valorOriginal = parseFloat(
      item.sTituloValorOriginal.replace(',', '.'),
    );

    if (sumValues[clienteNome]) {
      if (
        compareDateWithToday(item.sTituloDataVencimentoAtual.slice(0, 10)) ===
        'menor'
      ) {
        sumValues[clienteNome].somaValorVencido += valorOriginal;
      } else {
        sumValues[clienteNome].somaValorAVencer += valorOriginal;
      }
      sumValues[clienteNome].listagemBoletosCliente.push(item);
      sumValues[clienteNome].somaValorOriginal += valorOriginal;
    } else {
      if (
        compareDateWithToday(item.sTituloDataVencimentoAtual.slice(0, 10)) ===
        'menor'
      ) {
        sumValues[clienteNome] = {
          somaValorVencido: valorOriginal,
          somaValorAVencer: 0,
          somaValorOriginal: valorOriginal,
          sClienteCNPJ_CPF: item.sClienteCNPJ_CPF,
          listagemBoletosCliente: [item],
        };
      } else {
        sumValues[clienteNome] = {
          somaValorVencido: 0,
          somaValorAVencer: valorOriginal,
          somaValorOriginal: valorOriginal,
          sClienteCNPJ_CPF: item.sClienteCNPJ_CPF,
          listagemBoletosCliente: [item],
        };
      }
    }
  });

  const result = [];

  for (const clienteNome in sumValues) {
    result.push({
      clienteNome,
      somaValorVencido: sumValues[clienteNome].somaValorVencido.toString(),
      somaValorAVencer: sumValues[clienteNome].somaValorAVencer.toString(),
      somaValorOriginal: sumValues[clienteNome].somaValorOriginal.toString(),
      sClienteCNPJ_CPF: sumValues[clienteNome].sClienteCNPJ_CPF,
      listagemBoletosCliente: sumValues[clienteNome].listagemBoletosCliente,
    });
  }
  return result;
};

export const sendNotification = (text: string, config?: any) => {
  toast(text, {
    position: 'bottom-center',
    autoClose: config.autoClose || 5000,
    hideProgressBar: config.hideProgressBar ? true : false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    type: config.type || '',
  });
};

export const searchByRazaoSocial = (nomeBusca: any, arrayDeObjetos: any) => {
  const returnedArray = arrayDeObjetos.filter((elm: any) =>
    elm.sClienteNome.toLowerCase().includes(nomeBusca.toLowerCase()),
  );
  return returnedArray;
};

export const capitalizeAllWords = (data: string) => {
  return data
    .split(' ')
    .map(data => data.charAt(0).toUpperCase() + data.slice(1).toLowerCase())
    .join(' ');
};

export class Utils {
  static capitalizeFirstLetters(input: string): string {
    return input
      .split(' ')
      .map(word => {
        return word.replace(/^\w/, char => char.toUpperCase());
      })
      .join(' ');
  }
}

export const searchArrayOfObjects = (
  array: any,
  prop: string,
  value: string,
) => {
  for (let i = 0; i < array.length; i++) {
    const object = array[i];
    if (object[prop] === value) {
      return object;
    }
  }
};

export const truncate = (str: string, n: number) => {
  return str.length > n ? str.slice(0, n - 1) + '...' : str;
};

export const formatTimeStampToDate = (dataString: string) => {
  if (!dataString) {
    return '-';
  }

  const data = new Date(dataString);

  const dia = data.getDate().toString().padStart(2, '0');
  const mes = (data.getMonth() + 1).toString().padStart(2, '0');
  const ano = data.getFullYear();
  const horas = data.getHours().toString().padStart(2, '0');
  const minutos = data.getMinutes().toString().padStart(2, '0');

  return `${dia}/${mes}/${ano} às ${horas}:${minutos}`;
};

export const mapNumbersToString = (input: string): string => {
  const numberToWordMap: { [key: string]: string } = {
    '3': 'Televendas',
    '4': 'Nacionais',
    '5': 'Digital Info',
    '6': 'B2B',
    '7': 'Capilaridade',
  };

  return input
    .split(',')
    .map(number => numberToWordMap[number] || number)
    .join(', ');
};

export const returnUserLog = (elm: any) => {
  if (!elm) return '-';
  if (!elm.ativo)
    return `Usuário ${elm.usuarioEditado} foi desativado por ${elm.usuarioEdicao}.`;
  if (elm.ativo && !elm.areaAtuacao)
    return `Usuário ${elm.usuarioEditado} foi ativado.`;
  if (elm.ativo && elm.areaAtuacao)
    return `Área de atuação editada para ${mapNumbersToString(
      elm.areaAtuacao,
    )}.`;
};

export const formatCPF = (cpf: string) => {
  return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
};

export const formatCNPJ = (cnpj: string) => {
  return cnpj.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    '$1.$2.$3/$4-$5',
  );
};
