import { useCallback, useState } from 'react';
import { dealerProvider } from '../../services/api';
import { generateToken } from '../../services/token';
import {
  IGroupPermissionsState,
  // IEconomicGroup,
  IProfileGroup,
  IPermissionProfiles,
  IPaginationGroupProfiles,
} from '../../interfaces';
import { sendNotification } from '../../utils/functions';
import { IAreasActing } from '../../interfaces';

const toastConfig = {
  autoClose: 3000,
  hideProgressBar: true,
  type: 'success',
};

export const usePermissions = (): [
  IGroupPermissionsState,
  (active?: boolean, pagination?: { current: number; size: number }) => void,
  () => void,
  (updatedData: IProfileGroup | any, update?: boolean) => any,
  (id: number, active: boolean) => any,
  () => void,
  (email: string) => any,
] => {
  const [groupState, setGroupState] = useState<IGroupPermissionsState>({
    isLoading: false,
    error: null,
    modalLoading: false,
    economicGroups: [],
    profileGroups: [],
    permissionsProfile: [],
    profilePagination: {},
    areasActing: [],
  });

  const fetchData = useCallback(
    async (endpoint: string, onSuccess: (data: any) => void) => {
      try {
        setGroupState(prevState => ({ ...prevState, isLoading: true }));
        const accessToken = await generateToken();
        const response = await dealerProvider.get(endpoint, {
          headers: {
            Accept: '*/*',
            Authorization: `Bearer ${accessToken}`,
          },
        });

        onSuccess(response.data.data);

        setGroupState(prevState => ({ ...prevState, error: null }));
      } catch (err) {
        console.error('Erro ao buscar os dados:', err);
        setGroupState(prevState => ({ ...prevState, error: err as null }));
      } finally {
        setGroupState(prevState => ({ ...prevState, isLoading: false }));
      }
    },
    [],
  );

  const postData = useCallback(async (endpoint: string, data: any) => {
    try {
      setGroupState(prevState => ({ ...prevState, modalLoading: true }));
      const accessToken = await generateToken();
      const response = await dealerProvider.post(endpoint, data, {
        headers: {
          Accept: '*/*',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setGroupState(prevState => ({ ...prevState, error: null }));
      return response.data.data;
    } catch (err: any) {
      setGroupState(prevState => ({
        ...prevState,
        error: err as any,
        modalLoading: false,
      }));
      throw err;
    } finally {
      setGroupState(prevState => ({ ...prevState, modalLoading: false }));
    }
  }, []);

  const fetchAreasActing = useCallback(() => {
    fetchData(
      'api/AreasAtuacao/listar',
      (data: { areaAtuacaoDTO: IAreasActing[] }) => {
        const formattedAreas = data.areaAtuacaoDTO.map(
          (area: IAreasActing) => ({
            value: area.id,
            label: area.nome,
          }),
        );
        setGroupState(prevState => ({
          ...prevState,
          areasActing: formattedAreas,
        }));
      },
    );
  }, [fetchData]);

  const fetchProfilesGroups = useCallback(
    (active?: boolean, pagination?: { current: number; size: number }) => {
      const currentPage = pagination?.current;
      const pageSize = pagination?.size;

      const parameterMap: { [key: string]: string } = {
        true: 'ativo=true',
        false: 'ativo=false',
      };

      const parameter =
        active !== undefined ? parameterMap[active.toString()] : undefined;

      let url = parameter
        ? `api/Perfil/buscar?${parameter}`
        : 'api/Perfil/buscar';

      if (!active) {
        url = parameter
          ? `api/Perfil/buscar?${parameter}&Page=${currentPage}&PageSize=${pageSize}`
          : `api/Perfil/buscar?Page=${currentPage}&PageSize=${pageSize}`;
      }

      fetchData(
        url,
        (data: {
          perfis: IProfileGroup[];
          pagination?: IPaginationGroupProfiles;
        }) => {
          setGroupState(prevState => ({
            ...prevState,
            profileGroups: data.perfis,
            profilePagination: data.pagination,
          }));
        },
      );
    },
    [fetchData],
  );

  const fetchPermissionProfiles = useCallback(() => {
    fetchData(
      'api/Permissao/buscar',
      (data: { permissoes: IPermissionProfiles[] }) => {
        setGroupState(prevState => ({
          ...prevState,
          permissionsProfile: data.permissoes,
        }));
      },
    );
  }, [fetchData]);

  const insertOrEditPermissions = useCallback(
    async (updatedData: IProfileGroup | any, update?: boolean) => {
      const endpoint = `api/Perfil/${update ? 'editar' : 'cadastrar'}`;
      postData(endpoint, updatedData)
        .then((response: any) => {
          sendNotification(
            `Perfil ${response?.perfil?.nome || ''} ${
              update ? 'atualizados' : 'cadastrado'
            } com sucesso.`,
            toastConfig,
          );
        })
        .catch(err => {
          console.error('Erro ao editar:', err);
        })
        .finally(() => {
          fetchProfilesGroups(undefined, { current: 1, size: 10 });
        });
    },
    [postData],
  );

  const updateUserCode = useCallback(
    async (email: string) => {
      setGroupState(prevState => ({
        ...prevState,
        isLoading: true,
        error: null,
      }));
      try {
        const endpoint = `api/atualizar-codigo`;
        const response = await postData(endpoint, { email });
        return response;
      } catch (error) {
        setGroupState(prevState => ({ ...prevState, error: error as any }));
        throw error;
      } finally {
        setGroupState(prevState => ({ ...prevState, isLoading: false }));
      }
    },
    [postData, setGroupState],
  );

  const toggleActivatePermission = useCallback(
    async (id: number | any, active: boolean) => {
      const endpoint = `api/Perfil/trocastatus`;

      try {
        setGroupState(prevState => ({ ...prevState }));
        const accessToken = await generateToken();
        const response = await dealerProvider.put(
          endpoint,
          { id: id, ativo: active },
          {
            headers: {
              Accept: '*/*',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        setGroupState(prevState => ({ ...prevState, error: null }));
        sendNotification(
          `Perfil ${active ? 'ativo' : 'desativado'} com sucesso.`,
          toastConfig,
        );
      } catch (err: any) {
        setGroupState(prevState => ({
          ...prevState,
          error: err as any,
        }));
      } finally {
        setGroupState(prevState => ({ ...prevState }));
      }
    },
    [],
  );

  return [
    groupState,
    // fetchEconomicGroups,
    fetchProfilesGroups,
    fetchPermissionProfiles,
    insertOrEditPermissions,
    toggleActivatePermission,
    fetchAreasActing,
    updateUserCode,
  ];
};
