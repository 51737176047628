import styled from 'styled-components';
import * as c from '../palette';

export const List = styled.div`
  border: 1px solid #ccc;
  margin-bottom: 13px;
  padding: 12px 12px 12px 15px;
  border-radius: 20px;
  &.active {
    background: ${c.light};
  }
  &:hover {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .collapse-button {
    background: transparent;
    color: white;
    border: none;
    padding: 4px 10px 0;
    cursor: pointer;
  }
  .flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 7%;
  }
  h4 {
    width: 100%;
    .badge {
      margin-left: 1%;
      padding: 2px 7px;
      border-radius: 10px;
      align-items: center;
      background: ${c.lightGray};
      font-size: 13px;
      font-weight: 300;
      img {
        margin-right: 3px;
      }
    }
  }
`;

export const ItemContent = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: height 0.4s ease-in-out;

  &.active {
    margin-top: 3%;
    max-height: 500px;
  }

  .log-content {
    font-size: 12px;
  }
`;
