import React, { useState } from 'react';

import * as S from './styles';
import AlliedLogo from '../../assets/allied-empresas-logo.png';
import BackArrow from '../../assets/back-arrow.svg';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAccess } from '../../hooks';

interface IUserData {
  email: string;
  nome?: string;
  cnpj?: string;
  areaAtuacao?: string;
}

export const FirstRegisterForm: React.FC = () => {
  const { registerUser, isLoadingAPICall, error } = useAccess();
  const [isSent, setIsSent] = useState(false);

  // Hook do Formulário
  const {
    clearErrors,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserData>();

  const onSubmitColab: SubmitHandler<IUserData> = async user => {
    const values = {
      email: user.email,
      nome: user.email.split('@')[0],
      cnpj: '',
      areaAtuacao: '',
    };

    await registerUser(values).then(data => {
      if (data.status === 200) {
        setIsSent(true);
      }
    });
  };

  return (
    <S.Container>
      <S.LinkWrapper>
        <S.Image className="small" src={BackArrow}></S.Image>
        <S.TextLink href="/">Voltar para login</S.TextLink>
      </S.LinkWrapper>

      <S.Image src={AlliedLogo} />

      {!isSent && (
        <>
          <S.Text className="no-margin">
            Vamos começar a solicitação do seu acesso de{' '}
            <span className="bold"> Colaborador Allied. </span>
            Para solicitar o seu primeiro acesso, preencha as informações
            abaixo. Caso seu cadastro seja aprovado, você receberá a sua senha
            no e-mail informado.
          </S.Text>

          <S.Form onSubmit={handleSubmit(onSubmitColab)}>
            <S.Input
              type="text"
              id="email"
              placeholder="E-mail"
              {...register('email', {
                required: true,
              })}
            />
            {errors.email && errors.email.type === 'required' && (
              <S.ErrorText className="required-message">
                E-mail obrigatório
              </S.ErrorText>
            )}
            {error && (
              <S.ErrorText className="required-message">{error}</S.ErrorText>
            )}
            <S.Button
              className={isLoadingAPICall ? 'loading' : ''}
              type="submit"
            >
              {isLoadingAPICall ? 'Carregando...' : 'Cadastrar'}
            </S.Button>
          </S.Form>
        </>
      )}

      {isSent && (
        <>
          <S.Title>Solicitação realizada com sucesso! </S.Title>
          <S.Text className="final">
            Verifique a caixa de entrada no seu e-mail informado com o status da
            sua solicitação.
          </S.Text>
        </>
      )}
    </S.Container>
  );
};
