import styled from 'styled-components';

export const ModalContent = styled.div`
  padding: 20px;
  text-align: center;

  h2 {
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 10px;
  }

  button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }

  button:hover {
    background-color: #0056b3;
  }
`;
