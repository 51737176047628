import styled from 'styled-components';
import * as Tabs from '../../styles/lib/tabs';

export { Tabs };

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  color: #58595b;
`;

export const Content = styled.div`
  padding: 40px 20px;
  width: 100%;
  max-width: 1268px;
`;

export const Search = styled.form`
  margin: 24px 0px 24px;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
  }
`;

export const InputContainer = styled.div`
  position: relative;
  width: 72%;

  &.date-selection {
    width: 40%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 0 24px;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  border-radius: 29px;
  background: transparent;
  border: 1px solid #e0e0e0;
  padding: 15px 0px 15px 180px;
`;

export const ButtonsContainer = styled.div`
  width: 26%;
  gap: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 0 16px;
  }
`;

export const Button = styled.button`
  background-color: #f7941d;
  width: 100%;
  height: 50px;
  border-radius: 80px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;

  &.excel {
    border: 2px solid #f7941d;
    background: transparent;
    color: #58595b;
  }

  &.is-disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;

export const ExcelDownload = styled.img`
  height: 12px;
  width: 12px;
  margin: 0 5px 0 0;
`;

export const ButtonPlus = styled(Button)`
  width: 100%;
  max-width: 280px;
`;

export const Select = styled.select`
  padding: 7px;
  position: absolute;
  left: 9px;
  top: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 29px;
  background: #f4f4f4;
`;

export const Option = styled.option``;

export const SeparatorVetical = styled.div`
  position: absolute;
  bottom: 0;
  right: 310px;
  width: 1px;
  height: 50px;
  background: #e0e0e0;
`;

export const SeparatorHorizontal = styled(SeparatorVetical)`
  position: absolute;
  bottom: 24px;
  right: 150px;
  width: 5px;
  height: 2px;
  background: #e0e0e0;
`;

export const InputDate = styled.input`
  position: absolute;
  color: #58595b;
  border: 1px solid #e0e0e0;
  border-radius: 29px;
  padding: 10px;
`;

export const InputDateStart = styled(InputDate)`
  display: flex;
  top: 5px;
  right: 165px;
`;

export const InputDateEnd = styled(InputDate)`
  display: flex;
  top: 5px;
  right: 7px;
`;

export const Orders = styled.div``;

export const Title = styled.h1`
  font-size: 32px;
  margin-bottom: 24px;
  color: rgb(3, 59, 113);

  @media (max-width: 1024px) {
    font-size: 18px;
  }
`;

export const TitleSmall = styled(Title)`
  font-size: 24px;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const Items = styled.div``;

export const SearchMobile = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: block;
    margin: 0 0 32px;
    position: relative;
  }
`;

export const InputMobile = styled.input`
  width: 100%;
  height: 48px;
  padding: 15px 24px;
  border: 1px solid #e0e0e0;
  border-radius: 29px;
`;

export const IconMobile = styled.img`
  position: absolute;
  right: 15px;
  top: 15px;
`;

export const OrderPagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Pagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 28px;
  margin: 24px 0 0;
  font-size: 14px !important;
`;

export const PaginationArrow = styled.button`
  background: transparent;
  color: black;
  cursor: pointer;

  &:hover {
    color: #f7941d;
  }

  &.disabled {
    color: #ccc;
  }
`;

export const ItemsPerPage = styled.div`
  width: 250px;
`;

export const Text = styled.p`
  font-size: 14px;
  display: inline-block;
`;

export const DropdownAmount = styled.select`
  width: 20%;
  margin: 0 0 0 16px;
`;

export const DropdownOption = styled.option`
  width: 100%;
`;
