import React from 'react';
import * as S from './styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay } from 'swiper';

import ArrowLeft from '../../assets/arrow-left.png';
import ArrowRight from '../../assets/arrow-right.png';

import Money from '../../assets/money.png';
import Division from '../../assets/division.png';
import Box from '../../assets/box.png';

// Install Swiper modules
SwiperCore.use([Autoplay]);

export const Topbar: React.FC = () => {
  return (
    <S.ContainerFluid>
      <S.Container>
        <S.Content>
          <S.Icon src={ArrowLeft} />
          <Swiper
            slidesPerView={3}
            spaceBetween={50}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
            }}
          >
            <SwiperSlide>
              <S.InfoWrapper>
                <S.Icon src={Money} />
                <S.Text>Pagamento Faturado</S.Text>
              </S.InfoWrapper>
            </SwiperSlide>
            <SwiperSlide>
              <S.InfoWrapper>
                <S.Icon src={Box} />
                <S.Text>Entrega para todo o Brasil</S.Text>
              </S.InfoWrapper>
            </SwiperSlide>
            <SwiperSlide>
              <S.InfoWrapper>
                <S.Icon src={Division} />
                <S.Text>Produtos Exclusivos</S.Text>
              </S.InfoWrapper>
            </SwiperSlide>
            <SwiperSlide>
              <S.InfoWrapper>
                <S.Icon src={Box} />
                <S.Text>Equipe Especializada</S.Text>
              </S.InfoWrapper>
            </SwiperSlide>
          </Swiper>
          <S.Icon src={ArrowRight} />
        </S.Content>
      </S.Container>
    </S.ContainerFluid>
  );
};
