import React, { useState, useCallback, useEffect } from 'react';
import { Container, Content } from '../../styles/lib/grid';
import { Breadcrumb, DynamicFilter, Navbar, Topbar } from '../../components';
import { TitleContent } from '../../styles/lib/typography';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import {
  IDynamicFilterData,
  IDynamicFilterSelectOptions,
} from '../../interfaces';
import { ICommission, useCommissioning } from '../../hooks/useCommissioning';

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_DATE_START = '2024-01-01';
const DEFAULT_DATE_END = '2024-12-31';

export const Comissionamento: React.FC = () => {
  const [pagination, setPagination] = useState({
    current: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
  });

  const [filters, setFilters] = useState<
    IDynamicFilterData & { extraType?: string }
  >({
    type: 'nfe',
    term: '',
    dateStart: DEFAULT_DATE_START,
    dateEnd: DEFAULT_DATE_END,
    extraType: '0',
  });

  const [
    { isLoading, error, commissions, totalItems },
    fetchCommissions,
    exportCommissions,
  ] = useCommissioning();

  const searchFilters = useCallback(
    (formData: IDynamicFilterData & { extraType?: string }) => {
      const filtersWithExtraType = {
        ...formData,
        type: !formData.term ? '' : formData.type,
        extraType: formData.extraType || '0',
        currentPage: pagination.current,
        paginationSize: pagination.size,
      };

      setFilters(filtersWithExtraType);
      fetchCommissions(filtersWithExtraType);
    },
    [fetchCommissions],
  );

  const dataTreatment = useCallback((value: IDynamicFilterData) => {
    return { ...value, term: value.term.trim() };
  }, []);

  useEffect(() => {
    const filtersWithExtraType = {
      ...filters,
      type: !filters.term ? '' : filters.type,
      extraType: filters.extraType || '0',
    };

    fetchCommissions({
      ...filtersWithExtraType,
      extraType: filters.extraType || '0',
      currentPage: pagination.current,
      paginationSize: pagination.size,
    });
  }, [fetchCommissions, pagination.current]);

  const handleExport = useCallback(() => {
    exportCommissions({
      ...filters,
      type: !filters.term ? '' : filters.type,
      extraType: '0',
      currentPage: pagination.current,
      paginationSize: pagination.size,
    });
  }, [exportCommissions, filters]);

  const handleClearSearch = () => {
    setFilters({
      ...filters,
      term: '',
      dateStart: '',
      dateEnd: '',
    });
  };

  const tableColumns = [
    'Razão social',
    'Nº da Nota',
    'Parcela',
    'Data da Parcela',
    'Data do Pedido',
    'Val. Pedido',
    'Val. Comissão',
    'Status',
  ];

  const formatDateTime = (dateString: string) => {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('/').map(Number);
    return new Date(year, month - 1, day).toLocaleDateString('pt-BR');
  };

  const formatCurrencyBr = (currency: string) => {
    if (
      currency === undefined ||
      currency === null ||
      currency === '' ||
      currency === 'NaN'
    ) {
      return 'R$ -';
    } else {
      return Math.floor(parseFloat(currency)).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    }
  };

  const tableData = (commissions || []).map((commission: any) => ({
    'Razão social': commission.razaoSocial,
    'Nº da Nota': commission.numeroNota,
    Parcela: commission.percela,
    'Data da Parcela': formatDateTime(commission.dataParcela),
    'Data do Pedido': formatDateTime(commission.dataPedido),
    'Val. Pedido': formatCurrencyBr(commission.valorPedido),
    'Val. Comissão': formatCurrencyBr(commission.valorPago),
    Status: {
      text: commission.status,
      icon:
        commission.status === 'Pagamento Efetivado'
          ? 'check'
          : commission.status === 'A Liberar'
          ? 'times'
          : 'money',
    },
  }));

  const totalPages = Math.ceil(totalItems / DEFAULT_PAGE_SIZE);

  const handlePageChange = (page: number) => {
    setPagination(prevState => ({ ...prevState, current: page }));
  };

  const handlePageSizeChange = (size: number) => {
    setPagination(prevState => ({ ...prevState, size: size }));
  };

  return (
    <Container>
      <Topbar />
      <Navbar />
      <Content>
        <Breadcrumb paths={paths} />
        <TitleContent>Controle de comissionamento</TitleContent>

        <DynamicFilter
          filterSelectOptions={filterSelectOptions}
          extraFilterSelectOptions={extraFilterSelectOptions}
          filters={filters}
          calendar={true}
          isLoading={isLoading}
          searchFilters={searchFilters}
          dataTreatment={dataTreatment}
          setFilters={setFilters}
          handleExport={handleExport}
          handleClearSearch={handleClearSearch}
          placeholder="Busque por uma nota"
        />

        <DynamicTable
          columns={tableColumns}
          data={tableData}
          isLoading={isLoading}
          paginationSize={pagination.size}
          totalPages={totalPages}
          currentPage={pagination.current}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Content>
    </Container>
  );
};

const paths = [
  {
    title: 'Meu Painel',
    link: '/painel',
  },
  {
    title: 'Controle de comissionamento',
    link: '',
  },
];

const filterSelectOptions: IDynamicFilterSelectOptions[] = [
  { value: 'cnpj', description: 'CNPJ', placeholder: 'Busque por um CNPJ' },
  {
    value: 'nfe',
    description: 'Número da nota',
    placeholder: 'Busque por uma nota',
  },
];

const extraFilterSelectOptions: IDynamicFilterSelectOptions[] = [
  { value: '0', description: 'Todos' },
  { value: '1', description: 'A liberar' },
  { value: '2', description: 'Liberado' },
  { value: '4', description: 'Pagamento Efetivado' },
];
