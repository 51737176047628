import React, { useState, useEffect } from 'react';
import * as S from '../order-card-boleto/styles';

function DocumentViewer(props: any) {
  const [documentUrl, setDocumentUrl] = useState('');
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const base64Data = props.base64Data;
    const contentType = 'application/pdf';
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    const url = URL.createObjectURL(blob);
    setDocumentUrl(url);

    return () => URL.revokeObjectURL(url);
  }, [props.base64Data]);

  const handleError = () => {
    setHasError(true);
  };

  return (
    <div>
      {hasError ? (
        <S.MissingDoc>
          O título selecionado ainda não está disponível em nosso sistema! Por
          favor, tente novamente mais tarde.
        </S.MissingDoc>
      ) : (
        <iframe
          src={documentUrl}
          width="100%"
          height="800px"
          onError={handleError}
        />
      )}
    </div>
  );
}

export default DocumentViewer;
