import styled from 'styled-components';

export const ParallelList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;

  li {
    font-size: 15px;
    margin-right: 25px;
    margin-bottom: 25px;
    align-items: center;
    img {
      margin-right: 3px;
    }
  }
`;
