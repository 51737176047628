import styled from 'styled-components';

export const ContainerFluid = styled.div`
  width: 100%;
  padding: 24px;
  background-color: #f4f4f4;
  width: 100%;
  bottom: 0px;
  position: absolute;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1366px) {
    position: sticky;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1380px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 45px;
`;

export const SocialMediaInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Image = styled.img``;

export const TextWrapper = styled.div``;

export const Text = styled.p`
  font-size: 12px;
  color: #414040;
  line-height: 16.34px;
  font-weight: 400;
`;

export const IconWrapper = styled(InfoWrapper)`
  gap: 12px;
`;
