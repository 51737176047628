export const buildingItemCodes = ['CDR', 'CDS', 'RMA'];
export const dashboardItems = [
  {
    title: 'Controle de Acesso',
    icon: 'groups',
    path: '/controle-de-acesso/usuarios',
    isActive: false,
    code: 'GDU',
  },
  {
    title: 'Consulta de Pedidos',
    icon: 'box',
    path: '/pedidos',
    isActive: false,
    code: 'CDP',
  },
  {
    title: 'Envio do DANFE e XML',
    icon: 'paper',
    path: '/envio-danfe-xml',
    isActive: false,
    code: 'EDX',
  },
  {
    title: 'Portal de Boletos',
    icon: 'billet',
    path: '/boletos',
    isActive: false,
    code: 'PDB',
  },
  {
    title: 'Consulta de Rastreamento',
    icon: 'truck',
    path: '/rastreamento',
    isActive: false,
    code: 'CDR',
  },
  {
    title: 'Consulta de Seriais',
    icon: 'search',
    path: '/serial',
    isActive: false,
    code: 'CDS',
  },
  {
    title: 'RMA',
    icon: 'refresh',
    path: '/rma',
    isActive: false,
    code: 'RMA',
  },
  {
    title: 'Consulta de Logs',
    icon: 'logs',
    path: '/logs/consulta',
    isActive: false,
    code: 'CDL',
  },
  {
    title: 'Controle de Comissionamento',
    icon: 'money',
    path: '/comissionamento',
    isActive: false,
    code: 'CDC',
  },
];
