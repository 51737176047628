import styled from 'styled-components';

export const ContainerFluid = styled.div`
  width: 100%;
  padding: 32px;
  background-color: #123e71;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 850px;

  @media (max-width: 1380px) {
    max-width: 650px;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  width: 100%;
  max-width: 220px;

  @media (max-width: 1380px) {
    max-width: 200px;
  }
`;

export const ContainerFluidInfo = styled(ContainerFluid)`
  background-color: #f4f4f4;
  border-top: 1px solid #ced0ca;
`;

export const ContainerInfo = styled(Container)`
  align-items: start;
  max-width: 1248px;
`;

export const SocialInfo = styled(Item)`
  flex-direction: column;
  align-items: start;
  gap: 12px;
`;

export const ItemInfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: end;
  gap: 12px;
`;

export const ItemInfo = styled(Item)`
  flex-direction: column;
  align-items: start;
  gap: 12px;
`;

export const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #fff;

  span {
    color: #f7941d;
    font-weight: 700;
  }

  @media (max-width: 1380px) {
    font-size: 14px;
  }
`;

export const TextBig = styled(Text)`
  font-size: 20px;
  font-weight: 700;

  @media (max-width: 1380px) {
    font-size: 16px;
  }
`;

export const TextSmall = styled(Text)`
  font-size: 14px;
  color: #231f20;
  font-weight: 700;
`;

export const TextSmaller = styled(TextSmall)`
  font-size: 10px;
  color: #414040;
  font-weight: 600;
`;

export const Link = styled.a`
  font-size: 14px;
  color: #58595b;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

export const Icon = styled.img``;

export const SocialIcon = styled.img`
  max-width: 32px;
`;

export const Image = styled.img`
  height: 100%;
  position: absolute;
  bottom: 0;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
`;
