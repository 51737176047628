import styled from 'styled-components';
import * as Buttons from '../../styles/lib/buttons';

export { Buttons };
export const Icon = styled.img``;

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

export const Content = styled.div`
  padding: 40px 20px;
  width: 100%;
  max-width: 1268px;
  min-height: 670px;

  @media (max-width: 1280px) {
    max-width: 1000px;
  }

  @media (max-width: 1024px) {
    max-width: 768px;
  }
`;

export const ContentContainer = styled.div`
  margin: 40px 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px 64px;

  @media (max-width: 1280px) {
    max-width: 992px;
  }

  @media (max-width: 1024px) {
    max-width: 728px;
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  color: rgb(3, 59, 113);

  @media (max-width: 766px) {
    font-size: 18px;
  }
`;

export const Items = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InfoWrapper = styled.div`
  width: 100%;
  max-width: 550px;
`;

export const Info = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 400px;

  @media (max-width: 766px) {
    font-size: 14px;
  }
`;

export const Text = styled.div`
  color: #033b71;
  width: 100%;
  max-width: 150px;

  &.extended {
    width: 100%;
    max-width: 350px;
    color: #033b71;
  }

  &.bold {
    font-weight: 700;
  }

  &.vendor-code {
    width: auto;
    color: #033b71;
    padding-right: 3%;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    display: flex;
  }
`;
