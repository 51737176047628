import React, { useState } from 'react';
import * as S from './styles';
import ArrowDown from '../../assets/arrow-down-order.png';
import { UserCardProps } from '../../interfaces';

// Define o tipo do parâmetro cnpjArray como Array de strings
const formatCNPJ = (cnpjArray: string[]): string[] => {
  return cnpjArray.map(elm => {
    if (elm.length > 8) {
      return elm.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5',
      );
    } else {
      return elm.replace(/^(\d{2})(\d{3})(\d{3})/, '$1.$2.$3');
    }
  });
};

export const UserListItem: React.FC<UserCardProps> = ({ user, children }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <S.ListItem>
      <S.ListHeader onClick={() => setIsActive(!isActive)}>
        <p>{user.nome ? user.nome : '-'}</p>
        <span>Nome / Razão Social</span>
        <S.Arrow src={ArrowDown} className={isActive ? 'active' : ''} />
      </S.ListHeader>
      <S.ListBody className={isActive ? 'active' : ''}>
        {user.cnpj && Array.isArray(user.cnpj) && user.cnpj.length > 0 && (
          <p>
            {formatCNPJ(user.cnpj).map((formattedCNPJ, index) => (
              <span key={index}>
                <strong>{formattedCNPJ}</strong>
                <br />
              </span>
            ))}
            <span>CNPJ</span>
          </p>
        )}
        <p>
          {user.perfil && user.perfil.nome ? user.perfil.nome : '-'}
          <br />
          <span>Perfil</span>
        </p>
        <p>
          {user.grupoEconomico &&
          Array.isArray(user.grupoEconomico) &&
          user.grupoEconomico.length > 0
            ? user.grupoEconomico.map((grupo, index) => (
                <span key={index}>
                  <strong>{grupo}</strong>
                  <br />
                </span>
              ))
            : '-'}
          <span>Grupo Econômico</span>
        </p>
        <p>
          {user.email || '-'}
          <br />
          <span>E-mail</span>
        </p>
        <p>
          {user.areasAtuacao.length > 0
            ? user.areasAtuacao.map((area, index) => (
                <span key={index}>
                  <strong>
                    {area}
                    {index < user.areasAtuacao.length - 1 ? ', ' : ''}
                  </strong>
                </span>
              ))
            : '-'}
          <br />
          <span>Área de atuação</span>
        </p>
        {children}
      </S.ListBody>
    </S.ListItem>
  );
};
