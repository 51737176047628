import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

export const Content = styled.div`
  padding: 40px 20px;
  width: 100%;
  max-width: 1268px;
`;

export const Title = styled.h1`
  font-size: 32px;
  margin-bottom: 40px;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
`;

export const DetailContainer = styled.div``;

export const OrderHistory = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProgressOrder = styled.div`
  margin: 70px 0 180px;
  position: relative;
  width: 100%;
  max-width: 950px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Step = styled.div`
  position: relative;
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 50%;
  transition: background 1s;
  background: #ccc;
  z-index: 999;

  &.filled {
    background: #f7941d;
  }
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #ccc;
`;

export const ProgressBarFilled = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 0%;
  height: 2px;
  background: #f7941d;

  &.step1 {
    width: 33%;
  }

  &.step2 {
    width: 66%;
  }

  &.step3 {
    width: 100%;
  }

  /* &.step4 {
    width: 100%;
  } */
`;

export const OrderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 120px;
  margin: 4rem 0 0;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
  }
`;

export const Delivery = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 275px;
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  max-width: 400px;
`;

export const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 0 4px;
`;

export const SummaryTotal = styled(SummaryItem)`
  border-bottom: none;
`;

export const Payment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 220px;
`;

export const PaymentType = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  gap: 8px;
`;

export const Icon = styled.img`
  @media (max-width: 768px) {
    max-width: 32px;
  }
`;

export const TextFeatured = styled.h3`
  font-size: 24px;
  color: #033b71;
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: 400;

  span {
    font-weight: 700;
  }

  &.font-medium {
    font-weight: 600;
  }

  &.font-small {
    font-size: 12px;
  }

  &.gray {
    color: #777777;
  }
`;

export const TextError = styled(Text)`
  color: red;
  margin: 0 0 16px;
`;

export const FloatText = styled(Text)`
  font-size: 12px;
  text-align: center;
  position: absolute;
  top: 45px;
  left: -20px;
`;

export const OrderSummary = styled.div`
  border: 1px solid #e3e4e6;
  border-radius: 10px;
  margin: 88px auto 0;
  width: 100%;
  max-width: 1100px;
`;

export const Top = styled.div`
  background: #e6e6e6;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 32px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const Bottom = styled(Top)`
  background: transparent;
  flex-direction: column;
  display: flex;
  gap: 40px;
`;

export const OrderItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  @media (max-width: 768px) {
    align-items: start;
  }
`;

export const ItemDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: 768px) {
    align-items: start;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ItemPrice = styled.div``;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
