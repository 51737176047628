export const footerItems = [
  {
    title: 'Institucional',
    items: [
      {
        name: 'Políticas de Privacidade',
        link: 'https://alliedbrasil.com.br/politicas-e-privacidade/',
      },
      {
        name: 'Política Comercial',
        link: 'https://public.alliedempresas.com.br/texto-comercial/Politica-Comercial.pdf',
      },
      {
        name: 'Termos e Condições',
        link: 'https://public.alliedempresas.com.br/Termos-e-Uso/Termos-de-Uso.pdf',
      },
      {
        name: 'Trocas e Devoluções',
        link: 'https://public.alliedempresas.com.br/Troca-e-devolucoes/RMA.pdf',
      },
    ],
  },
  {
    title: 'Quem somos',
    items: [
      {
        name: 'História da Allied',
        link: 'https://www.alliedempresas.com.br/historia',
      },
    ],
  },
];
