import { useState } from 'react';
import { boletosProvider } from '../../services/api';
import { generateToken } from '../../services/token';
import { useStorage } from '../useStorage';
import { formatCoin, calcSumValues } from '../../utils/functions';

export const useBoletos = () => {
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBase64, setIsLoadingBase64] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  // Data
  const [response, setResponse] = useState<any>([]);
  const [responseResumed, setResponseResumed] = useState<any>([]);
  const [responseExcel, setResponseExcel] = useState<any>([]);
  const [qtdeRegistros, setQtdeRegistros] = useState(0);
  const [base64, setBase64] = useState<any>(null);

  const [emailSendStatus, setEmailSendStatus] = useState('');
  const [emailGroupSendStatus, setEmailGroupSendStatus] = useState('');
  const [errorsBase64, setErrorsBase64] = useState<Array<string> | null>(null);
  const [errors, setErrors] = useState<Array<string> | null>(null);

  const { getItemOnStorage } = useStorage();
  const vendorCode = getItemOnStorage('vendorCode') || '';
  const currentUser = getItemOnStorage('currentUser');
  const user = JSON.parse(currentUser || '');

  const vendorCodeNotPermissions = ['administrador', 'colaborador-allied'];

  const grupoEconomicoField =
    !vendorCodeNotPermissions.includes(user.perfil) &&
    user.gruposEconomicos.length &&
    Array.isArray(user.gruposEconomicos)
      ? [...new Set(user.gruposEconomicos)]
          .map((group: any) => `&ClienteGrupoEconomico=${group}`)
          .join('')
      : `&ClienteGrupoEconomico=${''}`;

  const vendedorField = user.vendedor
    ? `&Vendedor=${user.vendedor}`
    : `&Vendedor=${''}`;

  const vendedorAdicionalField =
    !vendorCodeNotPermissions.includes(user.perfil) && vendorCode
      ? `&VendedorAdicional=${vendorCode}`
      : `&VendedorAdicional=${''}`;

  const mandatoryFields =
    grupoEconomicoField + vendedorField + vendedorAdicionalField;

  const returnTitles = async (searchParams: any) => {
    const { dateStart, dateEnd, type, term, currentPage, pageSize } =
      searchParams;

    setResponse([]);
    setErrors(null);
    setIsLoading(true);

    let url = `api/Boletos/ConsultaBoletos?EmpresaNumero=29&EmpresaNumero=22&EmpresaNumero=26&EmpresaNumero=221&DataVencimentoInicial=${dateStart}&DataVencimentoFinal=${dateEnd}&Pagina=${currentPage}&PaginaQtdeRegistros=${pageSize}&EnviarEmail=${false}&DataEmissaoInicial=&DataEmissaoFinal=&TituloSerie=&TituloPortador=&NotaNFeChave=&TituloSaldo=C&Tipo=C&ComBase64=N&NumeroParcela=&EmailCliente=&EmailRevendedor=&NomePerfil=${
      user.perfil
    }`;

    if (type === 'cpfCnpj') {
      url += `&ClienteCNPJ_CPF=${term.replace(/\D/g, '')}`;
    } else if (type === 'razaoSocial') {
      url += `&ClienteNome=${term}`;
    } else if (type === 'notaFiscal') {
      url += `&TituloNumero=${term}`;
    }

    const areasAtuacaoParam = user.areasAtuacao?.length
      ? encodeURIComponent(user.areasAtuacao.join(','))
      : '';
    url += `&AreasAtuacao=${areasAtuacaoParam}`;

    try {
      const acessToken = await generateToken();

      const response = await boletosProvider.get(url + mandatoryFields, {
        headers: {
          Accept: '*/*',
          Authorization: `Bearer ${acessToken}`,
        },
      });

      const returnedResponse = response.data.data.d;

      setResponse(returnedResponse);
      setQtdeRegistros(returnedResponse[0].sTotalRegistros);
      setResponseExcel(
        returnedResponse.map((elm: any) => ({
          Razao_Social: elm.sClienteNome,
          CPF_CNPJ:
            elm.sClienteCNPJ_CPF.length !== 11
              ? elm.sClienteCNPJ_CPF.replace(
                  /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                  '$1.$2.$3/$4-$5',
                )
              : elm.sClienteCNPJ_CPF.replace(
                  /^(\d{3})(\d{3})(\d{3})(\d{2})/,
                  '$1.$2.$3-$4',
                ),
          Vencimento: elm.sTituloDataVencimentoAtual.slice(0, 10),
          Nota_Fiscal: elm.sTituloNumero,
          Parcela: elm.sTituloSeqVencto,
          Valor: formatCoin(elm.sTituloValorOriginal ?? ''),
        })),
      );
    } catch (e: any) {
      const errorMessage =
        e.response?.data?.errors[0] === 'ERRO NENHUM REGISTRO SELECIONADO'
          ? [
              'Não foram encontrados títulos para o filtro de busca selecionado.',
            ]
          : e.response?.data?.errors ?? ['Sem boletos listados.'];
      setErrors(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const returnTitlesResumed = async (searchParams: any) => {
    const { dateStart, dateEnd, type, term, currentPage, pageSize } =
      searchParams;

    setResponseResumed([]);
    setErrors(null);
    setIsLoading(true);

    let url = `api/Boletos/ConsultaBoletos?EmpresaNumero=29&EmpresaNumero=22&EmpresaNumero=26&EmpresaNumero=221&DataVencimentoInicial=${dateStart}&DataVencimentoFinal=${dateEnd}&Pagina=${currentPage}&PaginaQtdeRegistros=2000&EnviarEmail=false&DataEmissaoInicial=&DataEmissaoFinal=&TituloSerie=&TituloPortador=&NotaNFeChave=&TituloSaldo=C&Tipo=C&ComBase64=N&NumeroParcela=&EmailCliente=&EmailRevendedor=&NomePerfil=${user.perfil}`;

    if (type === 'cpfCnpj') {
      url += `&ClienteCNPJ_CPF=${term}`;
    } else if (type === 'razaoSocial') {
      url += `&ClienteNome=${term}`;
    } else if (type === 'notaFiscal') {
      url += `&TituloNumero=${term}`;
    }

    const areasAtuacaoParam = user.areasAtuacao?.length
      ? encodeURIComponent(user.areasAtuacao.join(','))
      : '';
    url += `&AreasAtuacao=${areasAtuacaoParam}`;

    try {
      const acessToken = await generateToken();

      const response = await boletosProvider.get(url + mandatoryFields, {
        headers: {
          Accept: '*/*',
          Authorization: `Bearer ${acessToken}`,
        },
      });

      const returnedResponse = response.data.data.d;

      setResponseResumed(calcSumValues(returnedResponse));
      setQtdeRegistros(returnedResponse[0].sTotalRegistros);
      setResponseExcel(
        returnedResponse.map((elm: any) => ({
          Razao_Social: elm.sClienteNome,
          CPF_CNPJ:
            elm.sClienteCNPJ_CPF.length !== 11
              ? elm.sClienteCNPJ_CPF.replace(
                  /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                  '$1.$2.$3/$4-$5',
                )
              : elm.sClienteCNPJ_CPF.replace(
                  /^(\d{3})(\d{3})(\d{3})(\d{2})/,
                  '$1.$2.$3-$4',
                ),
          Vencimento: elm.sTituloDataVencimentoAtual.slice(0, 10),
          Nota_Fiscal: elm.sTituloNumero,
          Parcela: elm.sTituloSeqVencto,
          Valor: formatCoin(elm.sTituloValorOriginal ?? ''),
        })),
      );
    } catch (e: any) {
      const errorMessage =
        e.response?.data?.errors[0] === 'ERRO NENHUM REGISTRO SELECIONADO'
          ? [
              'Não foram encontrados títulos para o filtro de busca selecionado.',
            ]
          : e.response?.data?.errors ?? ['Sem boletos listados.'];
      setErrors(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const loadBase64 = async (searchParams: any) => {
    const { dateStart, dateEnd, tituloNumero, numeroParcela } = searchParams;
    setBase64([]);
    setIsLoadingBase64(true);

    try {
      const acessToken = await generateToken();

      const areasAtuacaoParam = user.areasAtuacao?.length
        ? encodeURIComponent(user.areasAtuacao.join(','))
        : '';

      const response = await boletosProvider.get(
        `api/Boletos/ConsultaBoletos?EmpresaNumero=29&EmpresaNumero=22&EmpresaNumero=26&EmpresaNumero=221&DataVencimentoInicial=${dateStart}&DataVencimentoFinal=${dateEnd}&Pagina=${1}&PaginaQtdeRegistros=${100}&EnviarEmail=${false}&DataEmissaoInicial=${''}&DataEmissaoFinal=${''}&TituloSerie=${''}&TituloPortador=${''}&NotaNFeChave=${''}&TituloSaldo=${'C'}&Tipo=${'C'}&ComBase64=${'S'}&NumeroParcela=${numeroParcela}&EmailCliente=${''}&EmailRevendedor=${''}&ClienteNome=${''}&TituloNumero=${tituloNumero}&NomePerfil=${
          user.perfil
        }&AreasAtuacao=${areasAtuacaoParam}` + mandatoryFields,
        {
          headers: {
            Accept: '*/*',
            Authorization: `Bearer ${acessToken}`,
          },
        },
      );

      setBase64(response.data.data.d[0].sBoletoBase64);
    } catch (e: any) {
      setErrorsBase64(e.response?.data?.errors ?? ['Erro ao carregar boleto.']);
    } finally {
      setIsLoadingBase64(false);
    }
  };

  // Send Calls
  const sendBilletByEmail = async (emailList: any, boleto: any) => {
    setIsSendingEmail(true);
    setEmailSendStatus('');
    setEmailGroupSendStatus('');

    try {
      const acessToken = await generateToken();

      if (boleto.listagemBoletosCliente) {
        const notesArray = boleto.listagemBoletosCliente.map((e: any) => {
          return {
            empresaNumero: e.sEmpresaNumero,
            tituloNumero: e.sTituloNumero,
            dataEmissaoInicial: e.sTituloDataEmissao.substring(0, 10),
            dataEmissaoFinal: e.sTituloDataVencimentoAtual.substring(0, 10),
            clienteEmailFinanceiro: e.sClienteEmailFinanceiro,
          };
        });
        const valoresUnicos = new Set();

        const singleNotesArray = notesArray.filter(
          (obj: { [x: string]: any }) => {
            const valorDaPropriedade = obj['tituloNumero'];
            if (!valoresUnicos.has(valorDaPropriedade)) {
              valoresUnicos.add(valorDaPropriedade);
              return true;
            }
            return false;
          },
        );

        const responseSendNoteArray = await Promise.all(
          singleNotesArray.map(async (elm: any) => {
            return await boletosProvider.get(
              `api/Boletos/ConsultaBoletos?NomePerfil=${
                user.perfil
              }&EmpresaNumero=${elm.empresaNumero}&TituloNumero=${
                elm.tituloNumero
              }&NumeroParcela=${
                boleto.sTituloSeqVencto ?? ''
              }&TituloSaldo=${'C'}&Tipo=${'C'}&VendedorAdicional=${vendorCode}&EnviarEmail=${'TRUE'}&EmailCliente=${
                elm.clienteEmailFinanceiro
              }&EmailRevendedor=${emailList}&ComBase64=${'S'}&ClienteGrupoEconomico=${''}`,
              {
                headers: {
                  Accept: '*/*',
                  Authorization: `Bearer ${acessToken}`,
                },
              },
            );
          }),
        );

        if (responseSendNoteArray) {
          setEmailSendStatus('success');
        }
      } else {
        const responseBillet = await boletosProvider.get(
          `api/Boletos/ConsultaBoletos?NomePerfil=${
            user.perfil
          }&EmpresaNumero=${boleto.sEmpresaNumero}&TituloNumero=${
            boleto.sTituloNumero
          }&NumeroParcela=${
            boleto.sTituloSeqVencto ?? ''
          }&TituloSaldo=${'C'}&Tipo=${'C'}&VendedorAdicional=${vendorCode}&EnviarEmail=${'TRUE'}&EmailCliente=${
            emailList[1]
          }&EmailRevendedor=${
            emailList[0]
          }&ComBase64=${'S'}&ClienteGrupoEconomico=${''}`,
          {
            headers: {
              Accept: '*/*',
              Authorization: `Bearer ${acessToken}`,
            },
          },
        );

        if (responseBillet) {
          setEmailSendStatus('success');
        }
      }
    } catch (e: any) {
      setEmailSendStatus('error');
      return e.response;
    } finally {
      setIsSendingEmail(false);
      setEmailGroupSendStatus(
        'E-mails enviados. Verifique sua caixa de e-mails.',
      );
    }
  };

  const downloadBillet = (base64String: string, filename: string) => {
    if (base64String) {
      const byteCharacters = atob(base64String);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(url);
    } else {
      return null;
    }
  };

  // Returned Values
  const values = {
    response,
    responseResumed,
    responseExcel,
    qtdeRegistros,
    base64,
    isLoading,
    isSendingEmail,
    isLoadingBase64,
    errorsBase64,
    errors,
    emailSendStatus,
    emailGroupSendStatus,
    downloadBillet,
    sendBilletByEmail,
    returnTitles,
    returnTitlesResumed,
    loadBase64,
  };

  return values;
};
