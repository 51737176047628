import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  border: 1px solid #e3e4e6;
  border-radius: 10px;
  transition: ease 0.4s;
  cursor: pointer;
  transition: ease 0.4s;
  width: 100%;

  &:hover {
    border: 1px solid #f7941d;
  }

  @media (max-width: 1024px) {
    &.active {
      display: flex;
      border: 1px solid #f7941d;
    }
  }
`;

export const Top = styled.div`
  position: relative;
  background: #e6e6e6;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media (max-width: 1024px) {
    padding: 12px 16px;
  }
`;

export const Bottom = styled.div`
  background: transparent;
`;

export const BottomWrapper = styled(Top)`
  background: transparent;
  transition: ease 0.4s;

  @media (max-width: 1024px) {
    display: none;

    &.active {
      display: flex;
    }
  }
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 160px;

  &.wide {
    max-width: 320px;
  }

  @media (max-width: 1024px) {
    max-width: 140px;
  }
`;

export const BlockCell = styled(Cell)`
  max-width: 1920px;
  padding: 16px 32px 0;

  @media (max-width: 1024px) {
    padding: 16px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const Text = styled.p`
  font-size: 14px;
  color: #2c2c2c;

  span {
    font-weight: 700;
  }

  &.big {
    font-size: 18px;
    font-weight: 700;
  }

  @media (max-width: 1024px) {
    font-size: 12px;

    &.big {
      font-size: 14px;
    }
  }
`;

export const Input = styled.input`
  border: 1px solid #afafaf;
  border-radius: 8px;
  height: 40px;
  padding: 0 0 0 16px;
  width: 100%;
  max-width: 400px;
`;

export const ArrowDownMobile = styled.img`
  display: none;
  position: absolute;
  right: 15px;
  top: 15px;
  transition: ease 0.4s;

  @media (max-width: 1024px) {
    display: block;

    &.active {
      transform: rotate(180deg);
    }
  }
`;

export const ButtonMobile = styled.button`
  display: none;
  background: transparent;
  border-radius: 80px;
  border: 1px solid #f7941d;
  color: #f7941d;
  padding: 15px;
  margin: 0 0 16px;
  width: 100%;

  @media (max-width: 1024px) {
    &.active {
      display: block;
    }
  }
`;

export const LinkMobile = styled.a`
  width: 100%;
`;

export const LinkDesktop = styled(LinkMobile)`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const SendOptions = styled.div`
  border-top: 1px solid #dddddd;
  padding: 24px 32px;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  flex-wrap: wrap;
  gap: 16px;

  &.active {
    display: flex;
  }
`;

export const OrderDetails = styled.div`
  width: 100%;
  max-width: 200px;

  @media (max-width: 1024px) {
    max-width: 450px;
  }
`;

export const SendForm = styled.div`
  width: 100%;
  max-width: 790px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  margin: 16px 0 0;
  gap: 16px;
`;

export const ButtonWrapper = styled.a`
  width: 100%;
  max-width: 200px;

  @media (max-width: 1024px) {
    max-width: 450px;
  }
`;

export const SentMessage = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 32px 0 0;

  &.success {
    color: #60ab26;
    &::after {
      content: '✓';
      padding: 0 0 0 8px;
    }
  }

  &.failed {
    color: #ff0000;
    &::after {
      content: '✗';
      padding: 0 0 0 8px;
    }
  }
`;

export const SendingMessage = styled.p`
  color: black;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 32px 0 0;
`;
