import React, { useEffect, useState } from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as S from './styles';
import {
  LogListItem,
  ModalResponsive,
  Loading,
  Pagination,
  DynamicFilter,
} from '../../components';
import Details from '../../assets/buttons/details.svg';
import { useLogs, usePermissions } from '../../hooks';
import { formatTimeStampToDate } from '../../utils/functions';
import {
  IDynamicFilterData,
  IDynamicFilterSelectOptions,
} from '../../interfaces';

export const ProfileLogs: React.FC = () => {
  const { returnAllProfileLogs, usersLogs, isLoading, metadata, apiError } =
    useLogs();
  const [{ profileGroups }, fetchProfilesGroups] = usePermissions();
  const [activeUser, setActiveUser] = useState<any>(null);

  const [filters, setFilters] = useState<IDynamicFilterData>({
    type: '',
    term: '',
    dateStart: '2024-01-01',
    dateEnd: '2024-12-31',
  });

  const [OpenDetailsModal, setOpenDetailsModal] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    size: 20,
  });

  const totalPages = Math.ceil(
    metadata?.pagination?.totalItems / pagination.size,
  );

  useEffect(() => {
    fetchProfilesGroups(true);
    returnAllProfileLogs({
      ...filters,
      currentPage: pagination.current,
      pageSize: pagination.size,
      id: '',
    });
  }, [filters, pagination.current]);

  const paginationSearch = (index: number) => {
    if (index > 0 && index <= totalPages) {
      window.scrollTo(0, 0);
      setPagination({
        current: index,
        size: pagination.size,
      });
    }
  };

  const handleOpenDetails = (elm: any) => {
    setActiveUser(elm);
    setOpenDetailsModal(true);
  };

  const returnProfileLog = (elm: any) => {
    if (!elm) return '-';
    if (elm.tipoAlteracao)
      return (
        <S.Text>
          <S.Text className="blue bold inline">
            {elm.nomePermissaoAlterada}
          </S.Text>{' '}
          foi <S.Text className="bold inline">habilitado</S.Text>.
        </S.Text>
      );
    else
      return (
        <S.Text>
          <S.Text className="blue bold inline">
            {elm.nomePermissaoAlterada}
          </S.Text>{' '}
          foi <S.Text className="bold inline">desabilitado</S.Text>.
        </S.Text>
      );
  };

  const searchFilters = (formData: IDynamicFilterData) => {
    setFilters(formData);
    setPagination({ ...pagination, current: 1 });
  };

  const filterSelectOptions: IDynamicFilterSelectOptions[] = [
    { value: '', description: 'Todos os perfis' },
    ...profileGroups.map(group => ({
      value: group.id,
      description: group.nome,
    })),
  ];

  return (
    <S.Container>
      <S.Content>
        <DynamicFilter
          filterSelectOptions={filterSelectOptions}
          isLoading={isLoading}
          filters={filters}
          searchFilters={searchFilters}
          setFilters={setFilters}
          isInputDisabled={true}
          placeholder="Selecione um perfil para buscar."
          calendar
        />
        <S.Tabs.TabsContainer>
          {isLoading && <Loading />}
          {!isLoading && (
            <S.Tabs.TabHeader>
              <S.Tabs.TabCell>Pefil editado</S.Tabs.TabCell>
              <S.Tabs.TabCell>Editado por</S.Tabs.TabCell>
              <S.Tabs.TabCell>Data da Edição</S.Tabs.TabCell>
              <S.Tabs.TabCell className="expanded-xl">
                Ação realizada
              </S.Tabs.TabCell>
            </S.Tabs.TabHeader>
          )}
          {usersLogs &&
            usersLogs.map((elm: any) => {
              return (
                <S.Tabs.TabBody key={elm.id}>
                  <S.Tabs.TabCell>
                    {elm.nomePerfilAlteracao ?? '-'}
                  </S.Tabs.TabCell>
                  <S.Tabs.TabCell>
                    {elm.nomeUsuarioAlteracao ?? '-'}
                  </S.Tabs.TabCell>
                  <S.Tabs.TabCell>
                    {formatTimeStampToDate(elm.dataAlteracao)}
                  </S.Tabs.TabCell>
                  <S.Tabs.TabCell className="expanded-xl">
                    {returnProfileLog(elm ?? '-')}
                  </S.Tabs.TabCell>
                </S.Tabs.TabBody>
              );
            })}
        </S.Tabs.TabsContainer>
        <S.ListContainer>
          {isLoading && <Loading />}
          {usersLogs &&
            usersLogs.map((elm: any) => {
              return (
                <LogListItem key={elm.id} log={elm} type="profile">
                  <S.IconButton
                    className={'edit'}
                    onClick={() => handleOpenDetails(elm)}
                  >
                    <img src={Details} width={16} />
                    Detalhes
                  </S.IconButton>
                </LogListItem>
              );
            })}
        </S.ListContainer>
        {!isLoading && usersLogs?.length === 0 && (
          <S.Message>
            {apiError ? apiError : 'Nenhum resultado encontrado'}
          </S.Message>
        )}
        {!isLoading && usersLogs?.length !== 0 && (
          <Pagination
            startWithZero={false}
            currentPage={pagination.current}
            paginationSize={pagination.size}
            paginationSearch={paginationSearch}
            totalPages={totalPages}
          />
        )}
      </S.Content>

      {/* Modal */}
      <>
        <ModalResponsive
          title={'Detalhes de Log'}
          isOpenModal={OpenDetailsModal}
          handleOpenModal={setOpenDetailsModal}
        >
          <S.Featured>
            <S.Text>{returnProfileLog(activeUser)}</S.Text>
          </S.Featured>
          <S.Buttons>
            <S.IconButton
              className="close"
              onClick={() => setOpenDetailsModal(false)}
            >
              Fechar
            </S.IconButton>
          </S.Buttons>
        </ModalResponsive>
      </>

      <ToastContainer
        closeButton={false}
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </S.Container>
  );
};
