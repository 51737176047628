import React, { useState, useCallback } from 'react';
import { Table, TableContainer } from '../../styles/lib/table';
import {
  FaRegCheckCircle,
  FaRegHourglass,
  FaRegMoneyBillAlt,
} from 'react-icons/fa';
import { Pagination } from '../pagination';

interface DynamicTableProps {
  columns: string[];
  data: any[];
  isLoading?: boolean;
  paginationSize: number;
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
}

export const DynamicTable: React.FC<DynamicTableProps> = ({
  columns,
  data,
  isLoading = false,
  paginationSize,
  totalPages,
  currentPage,
  onPageChange,
  onPageSizeChange,
}) => {
  const renderCellContent = (col: string, value: any) => {
    if (col === 'Status' && value.icon) {
      const iconStyle: React.CSSProperties = {
        marginRight: '5px',
        color:
          value.icon === 'check'
            ? '#1BC167'
            : value.icon === 'money'
            ? '#2480E9'
            : '',
      };

      const textStyle: React.CSSProperties = {
        color:
          value.icon === 'check'
            ? '#1BC167'
            : value.icon === 'money'
            ? '#2480E9'
            : '',
      };

      const iconMap: Record<string, JSX.Element> = {
        check: <FaRegCheckCircle style={iconStyle} />,
        times: <FaRegHourglass style={iconStyle} />,
        money: <FaRegMoneyBillAlt style={iconStyle} />,
      };

      return (
        <span style={textStyle}>
          {iconMap[value.icon]}
          {value.text}
        </span>
      );
    }
    return value;
  };

  if (isLoading) {
    return <p>Carregando dados...</p>;
  }

  const startIndex = currentPage * paginationSize;
  const endIndex = startIndex + paginationSize;
  const paginatedData = data.slice(startIndex, endIndex);

  return (
    <div>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th key={index}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {totalPages > 0 ? (
              data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {columns.map((col, colIndex) => (
                    <td key={colIndex}>{renderCellContent(col, row[col])}</td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length}>Nenhum dado disponível</td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        paginationSize={paginationSize}
        paginationSearch={onPageChange}
        totalPages={totalPages}
        startWithZero={false}
      />
    </div>
  );
};
