import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  border: 1px solid #e3e4e6;
  border-radius: 10px;
  transition: ease 0.4s;
  cursor: pointer;
  transition: ease 0.4s;
  width: 100%;

  &:hover {
    border: 1px solid #f5f5f5;
  }

  &.active {
    display: flex;
    border: 1px solid #bcbec0;
    background: #f5f5f5;
  }
`;

export const Top = styled.div`
  position: relative;
  background: transparent;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
  align-items: start;
  padding: 12px 12px;
  border-radius: 10px;

  @media (max-width: 1024px) {
    padding: 12px 16px;
  }
`;

export const Bottom = styled.div`
  background: transparent;
`;

export const BottomWrapper = styled(Top)`
  background: transparent;
  transition: ease 0.4s;

  @media (max-width: 1024px) {
    display: none;

    &.active {
      display: flex;
    }
  }
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 160px;

  &.wide {
    max-width: 220px;
  }

  @media (max-width: 1024px) {
    max-width: 140px;
  }

  &.align-right {
    margin-right: 0;
  }
`;

export const RightAlignedContainer = styled.div`
  display: flex;
  gap: 136px;
  width: 44%;
  align-items: start;
  margin-left: auto;

  @media (max-width: 1280px) {
    width: 47%;
    gap: 44px;
  }
`;

export const BlockCell = styled(Cell)`
  max-width: 1920px;
  padding: 16px 32px 0;

  @media (max-width: 1024px) {
    padding: 16px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: #2c2c2c;

  span {
    font-weight: 400;
  }

  &.big {
    font-size: 18px;
    font-weight: 700;
  }

  @media (max-width: 1024px) {
    font-size: 12px;

    &.big {
      font-size: 14px;
    }
  }

  &.bold {
    .span {
      font-size: 14px;
      font-weight: 700;
      color: rgb(44, 44, 44);
    }
  }
`;

export const Input = styled.input`
  border: 1px solid #afafaf;
  border-radius: 8px;
  height: 40px;
  padding: 0 0 0 16px;
  width: 100%;
  max-width: 400px;
`;

export const ArrowDown = styled.img`
  position: absolute;
  right: 15px;
  top: 15px;
  transition: ease 0.4s;

  &.active {
    transform: rotate(180deg);
  }
`;

export const ButtonMobile = styled.button`
  display: none;
  background: transparent;
  border-radius: 80px;
  border: 1px solid #f5f5f5;
  color: #f5f5f5;
  padding: 15px;
  margin: 0 0 16px;
  width: 100%;

  @media (max-width: 1024px) {
    &.active {
      display: block;
    }
  }
`;

export const LinkMobile = styled.a`
  width: 100%;
`;

export const LinkDesktop = styled(LinkMobile)`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const SendOptions = styled.div`
  padding: 0 24px 24px;
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  flex-wrap: wrap;
  gap: 16px;
  position: relative;

  &.active {
    display: flex;
  }
`;

export const OrderDetails = styled.div`
  width: 100%;
  max-width: 200px;

  @media (max-width: 1024px) {
    max-width: 450px;
  }
`;

export const SendForm = styled.div`
  width: 100%;
  max-width: 790px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  margin: 16px 0 0;
  gap: 16px;
`;

export const ButtonWrapper = styled.a`
  width: 100%;
  max-width: 200px;

  @media (max-width: 1024px) {
    max-width: 450px;
  }
`;

export const SentMessage = styled.p`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 32px 0 0;

  &.success {
    color: #60ab26;
    &::after {
      content: '✓';
      padding: 0 0 0 8px;
    }
  }

  &.failed {
    color: #ff0000;
    &::after {
      content: '✗';
      padding: 0 0 0 8px;
    }
  }
`;

export const SendingMessage = styled.p`
  color: black;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 32px 0 0;
`;
