import React, { useState } from 'react';
import * as S from './styles';
import AlliedLogo from '../../assets/allied-empresas-logo.png';
import { Topbar } from '../../components';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import { EyeIcon, EyeOff } from 'lucide-react';
interface IUserData {
  password: string;
  passwordConfirm: string;
}

export const ResetPassword: React.FC = () => {
  const [searchParams] = useSearchParams();
  const user_email = searchParams.get('user_email');

  const { isLoading, apiErrors, resetPass } = useAuth();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState({
    title: `Olá, ${user_email}`,
    description:
      'Por gentileza, defina sua senha para o acesso ao nosso portal!',
    isRedefined: false,
  });

  // Hook do Formulário
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserData>();

  // Montagem e Consumo do Endpoit para LOGIN
  const onSubmit: SubmitHandler<IUserData> = async user => {
    const values = {
      password: user.password,
    };

    if (user.password === user.passwordConfirm) {
      const response = await resetPass(values);
      if (response && response.status === 200) {
        setIsSent(false);
        setMessage({
          title: 'Senha definida!',
          description: '',
          isRedefined: true,
        });
      }
    } else {
      setError('As senhas devem ser iguais.');
    }
  };

  return (
    <>
      <Topbar />
      <S.Container>
        <S.Image src={AlliedLogo} />
        {isSent ? (
          <>
            <S.Text>Digite a sua nova senha</S.Text>
            <S.Form onSubmit={handleSubmit(onSubmit)}>
              <S.PasswordWraper>
                <S.Input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  placeholder="Nova senha"
                  {...register('password', {
                    required: true,
                  })}
                />
                <S.PasswordControl
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeOff color="#7c7e80" size={20} strokeWidth={2} />
                  ) : (
                    <EyeIcon color="#7c7e80" size={20} strokeWidth={2} />
                  )}
                </S.PasswordControl>
              </S.PasswordWraper>

              <S.PasswordWraper>
                <S.Input
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirm-password"
                  placeholder="Confirme sua nova senha"
                  {...register('passwordConfirm', {
                    required: true,
                  })}
                />
                <S.PasswordControl
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <EyeOff color="#7c7e80" size={20} strokeWidth={2} />
                  ) : (
                    <EyeIcon color="#7c7e80" size={20} strokeWidth={2} />
                  )}
                </S.PasswordControl>
              </S.PasswordWraper>

              {error ? <S.TextError>{error}</S.TextError> : <></>}
              {apiErrors ? <S.TextError>{apiErrors[0]}</S.TextError> : <></>}
              <S.TextWrapper>
                <ul>
                  * A senha deve conter:
                  <li>No mínimo 8 caracteres;</li>
                  <li>Letra maiúscula;</li>
                  <li>Letra minúscula;</li>
                  <li>Número;</li>
                  <li>Caractere especial (#, @, !, ...).</li>
                </ul>
              </S.TextWrapper>
              {errors.password && errors.password.type === 'required' && (
                <S.TextError className="required-message">
                  Senha obrigatória
                </S.TextError>
              )}
              <S.Button className={isLoading ? 'loading' : ''} type="submit">
                {isLoading ? 'Salvando...' : 'Definir Senha'}
              </S.Button>
            </S.Form>
          </>
        ) : (
          <>
            <S.Title>{message.title}</S.Title>
            <S.Text>{message.description}</S.Text>
            {message.isRedefined ? (
              <S.Link href="https://autoservico.alliedempresas.com.br/">
                <S.Button>Fazer Login</S.Button>
              </S.Link>
            ) : (
              <S.Button onClick={() => setIsSent(!isSent)}>Vamos lá!</S.Button>
            )}
          </>
        )}
      </S.Container>
    </>
  );
};
