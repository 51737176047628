import styled from 'styled-components';

export const ContainerFluid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  margin-bottom: 16px;
  gap: 6px;
`;

export const Item = styled.div`
  display: flex;
  gap: 6px;
`;

export const Link = styled.a``;

export const Text = styled.p`
  font-size: 12px;
  color: #888;

  span {
    font-weight: 700;
  }
`;
