import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

export const Content = styled.div`
  padding: 40px 20px;
  width: 100%;
  max-width: 1268px;
  min-height: 670px;

  @media (max-width: 1280px) {
    max-width: 1000px;
  }

  @media (max-width: 1024px) {
    max-width: 768px;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  margin: 24px auto;
  display: none;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1024px) {
    display: flex;
  }
`;
