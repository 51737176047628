import styled from 'styled-components';
import * as Tabs from '../../styles/lib/tabs';
import ReactSelect from 'react-select';

export { Tabs };

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

export const Content = styled.div`
  padding: 0px 20px 40px;
  width: 100%;
  max-width: 1268px;
  min-height: 670px;

  @media (max-width: 1280px) {
    max-width: 1000px;
  }

  @media (max-width: 1024px) {
    max-width: 768px;
  }

  &.modify-content {
    padding: 40px 20px 0px 20px !important;
    min-height: 0px;
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  color: rgb(3, 59, 113);

  @media (max-width: 766px) {
    font-size: 18px;
  }
`;

export const Items = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InfoWrapper = styled.div`
  width: 100%;
  max-width: 550px;
`;

export const Info = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 1rem;

  @media (max-width: 766px) {
    font-size: 14px;
  }
`;

export const Text = styled.div`
  width: 100%;
  margin: 0 0 8px;

  &.extended {
    width: 100%;
    max-width: 350px;
    color: #033b71;
  }

  &.bold {
    font-weight: 700;
  }

  &.top-margin {
    margin: 16px 0 8px;
  }

  &.inline {
    display: inline;
  }

  &.blue {
    color: #133d6d;
  }

  &.small-danger {
    font-size: 12px;
    color: red;
    margin-top: 1%;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const IconButton = styled.button`
  background: transparent;
  padding: 8px;
  border-radius: 20px;
  display: flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  gap: 4px;
  transition: 0.2s ease all;
  cursor: pointer;

  &.disabled {
    color: white;
    opacity: 0.3;
  }

  &.edit {
    color: #58595b;
    border: 1px solid #58595b;

    &:hover {
      background-color: #e2e2e2;
    }
  }

  &.analisys {
    color: #f7941d;
    border: 1px solid #f7941d;
    &:hover {
      background-color: #f7951d2d;
    }
  }

  &.deactivate {
    color: #eb001b;
    border: 1px solid #eb001b;

    &:hover {
      background-color: #eb001b1f;
    }
  }

  &.reactivate {
    color: #0d6000;
    border: 1px solid #0d6000;

    &:hover {
      background-color: #0d60002e;
    }
  }

  &.text {
    color: #003b71;
  }

  &.close {
    background-color: #003b71;
    color: white;
    padding-left: 15px;
    padding-right: 15px;

    &:hover {
      background-color: #003a71d9;
    }
  }

  &.success {
    color: white;
    background-color: #0d6000;
    font-weight: 700;
    padding: 8px 16px;

    &:hover {
      background-color: #0d6000e2;
    }
  }

  &.danger {
    color: white;
    background-color: #d30000;
    font-weight: 700;
    padding: 8px 16px;

    &:hover {
      background-color: #d30000ce;
    }
  }

  &.update {
    color: white;
    background-color: #f7941d;
    font-weight: 700;
    padding: 8px 16px;

    &:hover {
      background-color: #f7951ddd;
    }
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 12px 8px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: #f4f4f4;

  &.floating {
    width: 155px;
    padding: 7px;
    border-radius: 16px;
    top: 8px;
    left: 8px;
    position: absolute;

    @media (max-width: 1024px) {
      width: 120px;
    }
  }
`;

export const Option = styled.option``;

export const SeparatorVetical = styled.div`
  position: absolute;
  bottom: 0;
  right: 310px;
  width: 1px;
  height: 50px;
  background: #e0e0e0;
`;

export const SeparatorHorizontal = styled(SeparatorVetical)`
  position: absolute;
  bottom: 24px;
  right: 150px;
  width: 5px;
  height: 2px;
  background: #e0e0e0;
`;

export const InputDate = styled.input`
  position: absolute;
  color: #58595b;
  border: 1px solid #e0e0e0;
  border-radius: 29px;
  padding: 10px;
`;

export const InputDateStart = styled(InputDate)`
  display: flex;
  top: 5px;
  right: 165px;
`;

export const InputDateEnd = styled(InputDate)`
  display: flex;
  top: 5px;
  right: 7px;
`;

export const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  gap: 16px;
  margin: 32px 0 0;
`;

export const SearchCell = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Search = styled.form`
  width: 100%;
  margin: 0px 0px 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 840px;

  @media (max-width: 1280px) {
    min-width: 150px;
  }

  @media (max-width: 1024px) {
    gap: 16px;
  }
`;

export const InputContainer = styled.div`
  width: 87%;
  position: relative;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  border-radius: 29px;
  background: transparent;
  border: 1px solid #e0e0e0;
  padding: 15px 25px 15px 175px;

  @media (max-width: 1024px) {
    padding: 15px 25px 15px 140px;
  }
`;

export const ButtonsContainer = styled.div`
  width: 10%;
  gap: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    min-width: 100% !important;
  }
`;

export const Button = styled.button`
  background-color: #fde4c7;
  color: #f7941d;
  width: 100%;
  height: 50px;
  border-radius: 80px;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
`;

export const CardInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border: 1px solid black;
  border-radius: 8px;
  padding: 16px 24px;
`;

export const CardCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 8px;

  &.left {
    border-right: 1px solid black;
    padding-right: 16px;
  }
`;

export const CardText = styled.p`
  display: inline-block;

  &.active {
    font-size: 14px;
    font-weight: 400;
    color: #60ab26;
  }

  &.inactive {
    font-size: 14px;
    font-weight: 400;
    color: #58595b;
  }

  &.bolder {
    font-size: 20px;
    font-weight: 700;
    color: black;
  }

  &.smallest {
    font-size: 14px;
    font-weight: 300;
    color: black;
  }

  &.normal {
    font-size: 14px;
    font-weight: 400;
    color: black;
  }

  &.info {
    font-size: 20px;
    font-weight: 300;
    color: black;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  margin: 24px auto;
  display: none;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1024px) {
    display: flex;
  }
`;

export const Featured = styled.div`
  border-radius: 16px;
  padding: 16px 0;

  &.background {
    padding: 16px;
    background-color: #ededed;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 16px;

  &.top-margin {
    margin-top: 16px;
  }

  &.between {
    justify-content: space-between !important;
  }
`;

export const RadioButtonWrapper = styled.div`
  color: #231f20;
  margin: 16px 0;
  font-size: 16px;

  .form-control {
    display: flex;
    gap: 8px;
    margin-top: 16px;
  }

  span {
    &.bolder {
      font-weight: 700;
    }
  }

  input[type='radio'] {
    /* ...existing styles */
    width: 24px;
    height: 24px;
    display: grid;
    place-content: center;
  }

  input[type='radio']::before {
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 16px;
    font-size: 14px;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 16px;
  }
`;

export const InputReason = styled.textarea`
  width: 100%;
  border: 1px solid #999;
  padding: 12px;
  border-radius: 12px;
  margin: 8px 0 16px;
`;

export const Pagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PagItem = styled.button`
  background: transparent;
  transition: 0.2s ease all;
  padding: 10px;
  border-radius: 50px;
  cursor: pointer;
  width: 47px;
  height: 47px;

  &:hover {
    background-color: #f7941d;
    font-weight: bold;
    color: white;
  }

  &.is-selected {
    font-weight: bold;
    background-color: #eee;
  }

  &.is-disabled {
    pointer-events: none;
    opacity: 0.7;
  }
`;

const inputStyles = `
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: #f4f4f4;
`;

const placeholderStyles = `
  font-size: 13px;
  font-weight: 500;
  color: black;
  margin-left: 6px;
`;

const indicatorStyles = `
  color: black;
  padding: 0px 1px;
  width: 18px;
  height: 18px;

  &:hover {
    color: black;
  }
`;

export const CustomMultiSelect = styled(ReactSelect)`
  .Select__control {
    ${inputStyles}

    .Select__value-container {
      padding: 6px 8px;

      .Select__placeholder {
        ${placeholderStyles}
      }
    }

    .Select__indicators {
      .Select__indicator-separator {
        display: none;
      }
      .Select__indicator {
        ${indicatorStyles}
      }
    }
  }

  .Select__menu {
    width: 100%;
  }
`;

export const PagPrevious = styled(PagItem)``;

export const PagNext = styled(PagItem)``;

export const Message = styled.h4`
  text-align: center;
  color: #777;
`;
