import styled, { css } from 'styled-components';
import * as c from '../palette';

const baseInputStyles = css`
  color: ${c.textGray};
  padding: 20px 15px;
  width: 100%;
  height: 48px;
  border-radius: 16px;
  font-size: 12px;
  margin: 8px 0px;
  &:focus {
    border: 1px solid black;
  }
`;

export const PrimaryInput = styled.input`
  background-color: #f2f2f2;
  border: 0;
  ${baseInputStyles};
  padding: 22px 17px;
`;

export const LightInput = styled.input`
  background-color: #fff;
  ${baseInputStyles};
  border: 1px solid black !important;
`;
