import styled from 'styled-components';

export const TabsRow = styled.tr`
  transition: ease 0.4s;
  cursor: pointer;
  display: none;
  transition: none;
  background-color: #f7f9fa;
  border: 1px solid white;

  /* &:nth-child(even) {
    background-color: #f7f9fa;
  } */

  &:hover {
    background-color: #eee;
  }

  &.show {
    display: table-row !important;
  }

  &.active {
    border-top: 2px solid #f7941d;
    border-left: 2px solid #f7941d;
    border-right: 2px solid #f7941d;
  }
`;

export const TabsData = styled.td`
  cursor: pointer;
  text-align: center;
  padding: 16px;

  &.start {
    text-align: start;
  }
`;

export const TabsRowForm = styled.tr`
  transition: ease 0.4s;
  cursor: pointer;
  display: none;
  border: 2px solid transparent;
  transition: none;
  background-color: #f6f6f6;

  &:hover {
  }

  &.show {
    display: table-row !important;
  }

  &.active {
    border-bottom: 2px solid #f7941d;
    border-left: 2px solid #f7941d;
    border-right: 2px solid #f7941d;

    &:last-child {
    }
    display: table-row !important;
  }
`;

export const TabsDataForm = styled.td`
  vertical-align: top;
  cursor: pointer;
  text-align: start;
  margin: 0 !important;
  padding: 30px 40px 40px;

  &.start {
    text-align: start;
  }

  &.limited-area {
    max-width: 350px;
  }
`;

export const TabDownload = styled.img`
  cursor: pointer;
  height: 16px;
  width: 16px;

  &:hover {
    opacity: 0.8;
  }
`;

export const TitleSend = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const SubitleSend = styled.p`
  font-size: 12px;
  margin-bottom: 10px;
`;

export const InputEmail = styled.input`
  padding: 0 20px;
  width: 100%;
  color: black;
  border: 1px solid #ccc;
  border-radius: 8px;
  height: 42px;
  margin-bottom: 20px;
`;

export const ButtonSend = styled.button`
  margin-top: 15px;
  background-color: white;
  border: 2px solid #f7941d;
  color: #f7941d;

  &.disabled {
    background-color: #bcbec0;
    border: 2px solid transparent;
    color: white;
    cursor: not-allowed;

    &:hover {
      background-color: #bcbec0;
      border: 2px solid transparent;
      color: white;
    }
  }

  &:hover {
    background-color: #f7941dee;
    color: #fff;
  }

  border-radius: 80px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  height: 48px;
  line-height: 18px;
  text-align: center;
  width: 200px;
  transition: ease 0.2s;
`;

export const Message = styled.button`
  padding: 12px 0;
  text-align: start;
  background: transparent;
  color: #585958;
  font-weight: 600;
`;

export const ErrorMessage = styled.button`
  padding: 12px;
  background: transparent;
  color: red;
  font-weight: 600;
`;

export const SuccessMessage = styled.button`
  padding: 12px;
  background: transparent;
  color: green;
  font-weight: 600;
`;

export const FileView = styled.div`
  background: white;
  min-height: 800px;
`;

export const GroupView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0.2rem;
`;

export const GroupItem = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  max-width: 100px;
  min-height: 45px;
  width: 100%;

  &.extended {
    max-width: 240px;
  }

  .email-wrapper {
    color: black;
    font-size: 12px;
    display: inline-block;
    border-radius: 80px;
  }

  .red {
    color: red !important;
  }
`;

export const MissingDoc = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px;
  text-align: center;
  padding: 0 20px;
`;
