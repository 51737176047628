import { useState } from 'react';
import {
  IOrderProps,
  IOrderSearch,
  IOrderStatusSearch,
} from '../../interfaces';
import { orderProvider } from '../../services/api';

import { MAX_ITEMS_LOADED, ITEMS_PER_PAGE } from '../../utils/constants';
import { useStorage } from '../useStorage';
import { generateToken } from '../../services/token';

export const useOrder = () => {
  const [orders, setOrders] = useState<IOrderProps[]>();
  const [errors, setErrors] = useState<Array<string> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [qtdeRegistros, setQtdeRegistros] = useState(0);

  const [staticOrders, setStaticOrders] = useState<any>([]);
  const [dynamicOrders, setDynamicOrders] = useState<any>([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [loadedItems, setLoadedItems] = useState<any>([]);

  const { getItemOnStorage } = useStorage();
  const vendorCode = getItemOnStorage('vendorCode') || '';
  const currentUser = getItemOnStorage('currentUser');
  const user = JSON.parse(currentUser || '');

  const areaAtuacoesField = user.areasAtuacao
    ? `&AreasAtuacao=${user.areasAtuacao}`
    : `&AreasAtuacao=${''}`;

  const grupoEconomicoField = user.grupoEconomico
    ? `&saClienteGrupoEconomico=${user.grupoEconomico}`
    : `&saClienteGrupoEconomico=${''}`;

  const vendedorField = user.vendedor
    ? `&Vendedor=${user.vendedor}`
    : `&Vendedor=${''}`;

  const vendedorAdicionalField = vendorCode
    ? `&pedidoVendedorAdicional=${vendorCode}`
    : `&pedidoVendedorAdicional=${''}`;

  const mandatoryFields =
    grupoEconomicoField +
    vendedorField +
    vendedorAdicionalField +
    areaAtuacoesField;

  const getNewPaginatedOrders = async (searchParams: any) => {
    const { term, type, dateStart, dateEnd, isNewSearch, page, size } =
      searchParams;
    setIsLoading(true);
    setErrors([]);

    const subscriptionNumber = type === `cnpj` ? `&clienteCNPJ=${term}` : ``;

    if (isNewSearch) {
      setLoadedItems([]);
      setCurrentPage(1);
    }

    try {
      const acessToken = await generateToken();

      const response = await orderProvider.get(
        `/api/PedidoDeVenda/Cabecalho?NomePerfil=${
          user.perfil
        }&qtdeRegistros=100&sPaginaQtdeRegistros=${size}&sPagina=${page}&empresaNumero=22&empresaNumero=26&empresaNumero=29&empresaNumero=221&pedidoTipo=Venda&pedidoStatus=Aprovado&pedidoStatus=Liberacao&pedidoStatus=Cancelado&pedidoStatus=Reprovado&dataDigitacaoInicial=${dateStart}&dataDigitacaoFinal=${dateEnd}&dataParaFaturamentoInicial=${dateStart}&dataParaFaturamentoFinal=${dateEnd}&pedidoOperadorLogisticoInformado=T&pedidoSaldo=T&notanumero=${
          type === 'numNota' ? term : ''
        }&pedidocliente=${type === 'numPedEcom' ? term : ''}&clientenome=${
          type === 'clienteNome' ? term : ''
        }&pedidonumero=${type === 'numPedAllied' ? term : ''}` +
          subscriptionNumber +
          mandatoryFields,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${acessToken}`,
          },
        },
      );
      const responseData = response.data.data.d;

      setQtdeRegistros(responseData[0].sTotalRegistros);
      setLoadedItems(responseData);
    } catch (e: any) {
      setErrors(e.response?.data?.errors ?? ['Erro ao carregar pedido(s).']);
    } finally {
      setCurrentPage(prev => prev + 1);
      setIsLoading(false);
    }
  };

  const getOrderDetails = async (searchParams: IOrderSearch) => {
    const { term, type, dateStart, dateEnd } = searchParams;
    setIsLoading(true);
    setErrors([]);
    setOrders([]);

    try {
      const acessToken = await generateToken();

      const response = await orderProvider.get(
        `/api/PedidoDeVenda/Itens?empresaNumero=22&empresaNumero=26&empresaNumero=29&empresaNumero=221&pedidoTipo=Venda&pedidoStatus=Aprovado&pedidoStatus=Liberacao&pedidoStatus=Cancelado&pedidoStatus=Reprovado&dataDigitacaoInicial=${dateStart}&dataDigitacaoFinal=${dateEnd}&dataParaFaturamentoInicial=${dateStart}&dataParaFaturamentoFinal=${dateEnd}&pedidoOperadorLogisticoInformado=T&pedidoSaldo=T${
          vendorCode
            ? `&pedidoVendedorAdicional=${vendorCode}`
            : '&pedidoVendedorAdicional='
        }&qtdeRegistros=5&notanumero=${
          type === 'numNota' ? term : ''
        }&pedidocliente&clientenome=${
          type === 'clienteNome' ? term : ''
        }&pedidonumero=${type === 'numPedAllied' ? term : ''}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${acessToken}`,
          },
        },
      );

      return response.data.data.d;
    } catch (e: any) {
      return e.response?.data;
    } finally {
      setIsLoading(false);
    }
  };

  const getOrderStatus = async (searchParams: IOrderStatusSearch) => {
    const { orderNumber, cnpjNumber } = searchParams;
    setIsLoading(true);

    try {
      const acessToken = await generateToken();

      const response = await orderProvider.get(
        `/api/PedidoDeVenda/Status?empresaNumero=22&empresaNumero=26&empresaNumero=29&empresaNumero=221&pedidoNumero=${orderNumber}&clienteCNPJ=${cnpjNumber}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${acessToken}`,
          },
        },
      );

      return response.data;
    } catch (e: any) {
      return e.response?.data;
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreOrders = () => {
    setStaticOrders([...staticOrders.slice(MAX_ITEMS_LOADED, 100)]);
    setDynamicOrders([
      ...dynamicOrders,
      ...staticOrders.slice(0, MAX_ITEMS_LOADED),
    ]);
  };

  const values = {
    orders,
    dynamicOrders,
    staticOrders,
    errors,
    isLoading,
    loadedItems,
    getOrderDetails,
    getOrderStatus,
    loadMoreOrders,
    getNewPaginatedOrders,
    qtdeRegistros,
  };
  return values;
};
