import React, { useEffect, useState } from 'react';
import * as S from './styles';
import {
  Topbar,
  Navbar,
  DashboardFooter,
  DashboardItem,
} from '../../components';

import { buildingItemCodes, dashboardItems } from '../../utils/mocks/dashboard';

interface DashboardItem {
  title: string;
  icon: string;
  path: string;
  isActive: boolean;
  code: string;
}

export const Dashboard: React.FC = () => {
  const [permissions, setPermissions] = useState<DashboardItem[] | null>(null);

  useEffect(() => {
    const user = localStorage.getItem('currentUser');

    if (user) {
      const userData = JSON.parse(user);
      const userDataMapped = mapUserAccess(userData);

      setPermissions(userDataMapped);
    }
  }, []);

  const mapUserAccess = (accessData: any) => {
    const userAccessItems = dashboardItems.map(elm => {
      if (accessData.roles.includes(elm.code)) {
        return {
          ...elm,
          isActive: !buildingItemCodes.includes(elm.code),
        };
      }
      return elm;
    });

    return userAccessItems;
  };

  return (
    <S.Container>
      <Topbar />
      <Navbar />
      <S.Content>
        <S.Title>Meu Painel</S.Title>
        <S.ContentContainer>
          {permissions &&
            permissions.map((item, index) => {
              return (
                <DashboardItem
                  isActive={item.isActive}
                  title={item.title}
                  icon={item.icon}
                  path={item.path}
                  key={index}
                />
              );
            })}
        </S.ContentContainer>
      </S.Content>
      <DashboardFooter />
    </S.Container>
  );
};
