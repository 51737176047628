import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

export const Content = styled.div`
  padding: 40px 20px;
  width: 100%;
  max-width: 1268px;
`;

export const Search = styled.form`
  margin: 40px 0px 55px;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  width: 85%;
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  border-radius: 29px;
  background: transparent;
  border: 1px solid #e0e0e0;
  padding: 15px 25px 15px 260px;
`;

export const Button = styled.button`
  background-color: #f7941d;
  width: 12%;
  height: 50px;
  border-radius: 80px;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
`;

export const ButtonPlus = styled(Button)`
  width: 100%;
  max-width: 280px;
`;

export const Select = styled.select`
  padding: 7px;
  position: absolute;
  left: 9px;
  top: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 29px;
  background: #f4f4f4;
`;

export const Option = styled.option``;

export const SeparatorVetical = styled.div`
  position: absolute;
  bottom: 0;
  right: 290px;
  width: 1px;
  height: 50px;
  background: #e0e0e0;
`;

export const SeparatorHorizontal = styled(SeparatorVetical)`
  position: absolute;
  bottom: 24px;
  right: 142px;
  width: 5px;
  height: 2px;
  background: #e0e0e0;
`;

export const InputDate = styled.input`
  position: absolute;
  color: #58595b;
  border: 1px solid #e0e0e0;
  border-radius: 29px;
  padding: 10px;
`;

export const InputDateStart = styled(InputDate)`
  display: flex;
  top: 5px;
  right: 165px;
`;

export const InputDateEnd = styled(InputDate)`
  display: flex;
  top: 5px;
  right: 7px;
`;

export const Orders = styled.div``;

export const Title = styled.h1`
  font-size: 32px;
  margin-bottom: 24px;
  color: rgb(3, 59, 113);

  @media (max-width: 1024px) {
    font-size: 18px;
  }
`;

export const TitleSmall = styled(Title)`
  font-size: 24px;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const Items = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SearchMobile = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: block;
    margin: 0 0 32px;
    position: relative;
  }
`;

export const InputMobile = styled.input`
  width: 100%;
  height: 48px;
  padding: 15px 24px;
  border: 1px solid #e0e0e0;
  border-radius: 29px;
`;

export const IconMobile = styled.img`
  position: absolute;
  right: 15px;
  top: 15px;
`;

export const OrderPagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingAnimated = styled.img`
  width: 100px;
  margin: 0 auto;
`;
