import React, { useState } from 'react';
import * as S from './styles';

import LoadingAnimated from '../../assets/loading-animated.svg';

export const Loading: React.FC = () => {
  return (
    <>
      <S.LoadingAnimated src={LoadingAnimated} />
    </>
  );
};
