import React from 'react';
import * as S from './styles';
import { Topbar, FirstRegisterForm, LoginFooter } from '../../components';

export const FirstRegister: React.FC = () => {
  return (
    <S.Container>
      <Topbar />
      <FirstRegisterForm />
      <LoginFooter />
    </S.Container>
  );
};
