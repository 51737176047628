import React from 'react';
import * as S from './styles';

interface IPath {
  title: string;
  link: string;
}

interface IBreadcrumb {
  paths: Array<IPath>;
}

export const Breadcrumb: React.FC<IBreadcrumb> = ({ paths }) => {
  return (
    <S.ContainerFluid>
      {paths.map((element, index) => {
        return (
          <S.Item key={index}>
            <S.Link href={index === paths.length - 1 ? '' : element.link}>
              <S.Text>
                {index === paths.length - 1 ? (
                  <span>{element.title}</span>
                ) : (
                  <>{element.title}</>
                )}
              </S.Text>
            </S.Link>
            <S.Text>{index !== paths.length - 1 && '>'}</S.Text>
          </S.Item>
        );
      })}
    </S.ContainerFluid>
  );
};
