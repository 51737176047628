export const profileFields = [
  {
    name: 'revendedor-allied', // OK
    fields: [
      'cnpj',
      'razaoSocial',
      'nomeFantasia',
      'inscricaoEstadual',
      'email',
      'telefone',
      'endereco',
      'dadosBancarios',
    ],
  },
  {
    name: 'comprador-revenda', // OK
    fields: ['cpfCnpj', 'razaoSocial', 'email'],
  },
  {
    name: 'vendedor-revenda', // OK
    fields: ['cpfCnpj', 'razaoSocial', 'email'],
  },
  {
    name: 'colaborador-allied', // OK
    fields: ['email'],
  },
  {
    name: 'vendedor-allied', // OK
    fields: ['email', 'cpfCnpj', 'vendedor'],
  },
  {
    name: 'cliente-final',
    fields: [
      'email',
      'cpfCnpj',
      'nome',
      'areaDeAtuacao',
      'inscricaoEstadual',
      'grupoEconomico',
    ],
  },
  {
    name: 'administrador', // OK
    fields: ['email'],
  },
];
