import styled from 'styled-components';

export const ModalHeader = styled.div`
  padding: 24px;
`;

export const ModalTitle = styled.h4`
  color: #003b71;
  font-size: 18px;
  font-weight: 600;
`;

export const ModalBody = styled.div`
  padding: 0 24px 24px;
  .log-content {
    font-size: 10.5px !important;
  }
`;
