import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import * as S from './styles';
import {
  Topbar,
  Navbar,
  DashboardFooter,
  Breadcrumb,
} from '../../../components';
import { Users } from '../../../pages/users';
import { Permissions } from '../../../pages/permissions';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStorage } from '../../../hooks';

const TABS = {
  USERS: 'users',
  PERMISSIONS: 'permissions',
};

const PATHS = {
  PANEL: '/painel',
  ACCESS_CONTROL: '/controle-de-acesso/usuarios',
  USERS: '/controle-de-acesso/usuarios',
  PERMISSIONS: '/controle-de-acesso/perfis',
};

export const Access: React.FC = () => {
  const [activeTab, setActiveTab] = useState(TABS.USERS);
  const [totalEmAnalise, setTotalEmAnalise] = useState<string | null>(null);
  const [paths, setPaths] = useState([
    {
      title: 'Meu Painel',
      link: PATHS.PANEL,
    },
    {
      title: 'Controle de Acesso',
      link: PATHS.USERS,
    },
  ]);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const updatePaths = (title: string, link: string) => {
    setPaths([
      {
        title: 'Meu Painel',
        link: PATHS.PANEL,
      },
      {
        title: 'Controle de acesso',
        link: PATHS.ACCESS_CONTROL,
      },
      {
        title,
        link,
      },
    ]);
  };

  const { getItemOnStorage } = useStorage();
  const user = JSON.parse(getItemOnStorage('currentUser') || '');

  useEffect(() => {
    if (pathname.includes('usuarios')) {
      setActiveTab(TABS.USERS);
      updatePaths('Gestão de Usuários', PATHS.USERS);
    } else if (pathname.includes('perfis')) {
      setActiveTab(TABS.PERMISSIONS);
      updatePaths('Gestão de Perfis', PATHS.PERMISSIONS);
    } else {
      setActiveTab(TABS.USERS);
      updatePaths('Gestão de Usuários', PATHS.USERS);
    }
  }, [pathname]);

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
    navigate(tabName === TABS.USERS ? PATHS.USERS : PATHS.PERMISSIONS);
  };

  return (
    <S.Container>
      <Topbar />
      <Navbar />
      <S.Content className="modify-content">
        <Breadcrumb paths={paths} />
        <S.Title style={{ marginBottom: '3%' }}>Controle de Acesso</S.Title>

        <S.Tabs.TabsNavigation>
          <S.Tabs.TabsSelector className="secondary">
            <S.Tabs.TabSelector
              className={activeTab === TABS.USERS ? 'active' : ''}
              onClick={() => handleTabClick(TABS.USERS)}
            >
              Usuários
              <S.Tabs.TabBadge
                className={activeTab === TABS.USERS ? '' : 'inactive'}
              >
                {totalEmAnalise || '-'}
              </S.Tabs.TabBadge>
            </S.Tabs.TabSelector>
            {user.perfil === 'administrador' && (
              <S.Tabs.TabSelector
                className={activeTab === TABS.PERMISSIONS ? 'active' : ''}
                onClick={() => handleTabClick(TABS.PERMISSIONS)}
              >
                Perfis
              </S.Tabs.TabSelector>
            )}
          </S.Tabs.TabsSelector>
        </S.Tabs.TabsNavigation>
      </S.Content>

      {activeTab === TABS.USERS ? (
        <Users setTotalEmAnalise={setTotalEmAnalise} />
      ) : null}
      {activeTab === TABS.PERMISSIONS ? <Permissions /> : null}

      <DashboardFooter />
    </S.Container>
  );
};
