import styled from 'styled-components';

export const Content = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 32px 10px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #033b7133;
  width: 100%;
  max-width: 294px;
  min-height: 130px;
  transition: ease 0.2s;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 14px 0px #033b7133;
  }

  &.inactive {
    display: none;
    cursor: not-allowed;
    &:hover {
      box-shadow: 0px 2px 4px 0px #033b7133;
    }
  }

  @media (max-width: 1280px) {
    max-width: 308px;
  }

  @media (max-width: 1024px) {
    max-width: 355px;
  }

  @media (max-width: 766px) {
    display: flex;
    flex-direction: row;
    justify-content: start;
    max-width: 1000px;
    padding: 16px 24px;
    min-height: 64px;
  }
`;

export const Text = styled.div`
  color: #033b71;
  font-size: 14px;
  font-weight: 400;
`;

export const Image = styled.img``;
