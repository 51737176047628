import gtmConfig from './gtmConfig';

interface CustomWindow extends Window {
  dataLayer: any[];
}

export const loadGoogleTagManager = () => {
  if (process.env.REACT_APP_KEY_ENV === 'PRD' && gtmConfig.containerId) {
    (function (w: CustomWindow, d: Document, s: string, l: string, i: string) {
      w.dataLayer = w.dataLayer || [];
      w.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

      if (d instanceof Document) {
        const f = d.getElementsByTagName(s)[0];
        if (f instanceof HTMLElement && f.parentNode !== null) {
          const j = d.createElement(s);
          const dl = l !== 'dataLayer' ? '&l=' + l : '';

          if (j instanceof HTMLScriptElement) {
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          }
        }
      }
    })(window as any, document, 'script', 'dataLayer', gtmConfig.containerId);
  }
};
