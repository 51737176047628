export const useStorage = () => {
  const setItemOnStorage = (key: string, value: any) => {
    localStorage.setItem(key, value);
  };

  const getItemOnStorage = (key: string) => {
    return localStorage.getItem(key);
  };

  const removeItemOnStorage = (key: string) => {
    return localStorage.removeItem(key);
  };

  return {
    setItemOnStorage,
    getItemOnStorage,
    removeItemOnStorage,
  };
};
