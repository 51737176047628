import styled from 'styled-components';

export const ListItem = styled.div`
  width: 100%;
  -webkit-box-shadow: 0px 2px 14px -5px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 2px 14px -5px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 2px 14px -5px rgba(0, 0, 0, 0.4);
  padding: 12px 16px;
  border-radius: 8px;
  position: relative;
`;

export const ListHeader = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 700;

  span {
    font-weight: 400;
  }
`;

export const ListBody = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  padding: 8px 0;
  display: none;
  flex-direction: column;
  gap: 12px;

  span {
    font-weight: 400;
  }

  &.active {
    display: flex;
  }
`;

export const Arrow = styled.img`
  display: none;
  position: absolute;
  right: 8px;
  top: 10px;
  transition: ease 0.4s;

  @media (max-width: 1024px) {
    display: block;

    &.active {
      transform: rotate(180deg);
    }
  }
`;
