import React, { useEffect, useState } from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as S from './styles';
import {
  LogListItem,
  ModalResponsive,
  Loading,
  Pagination,
  DynamicFilter,
} from '../../components';
import Details from '../../assets/buttons/details.svg';
import { useLogs } from '../../hooks';
import {
  formatTimeStampToDate,
  mapNumbersToString,
} from '../../utils/functions';
import {
  IDynamicFilterData,
  IDynamicFilterSelectOptions,
} from '../../interfaces';

export const UsersLogs: React.FC = () => {
  const { returnAllUsersLogs, usersLogs, isLoading, metadata } = useLogs();

  const [activeUser, setActiveUser] = useState<any>(null);

  const [OpenDetailsModal, setOpenDetailsModal] = useState(false);

  const [filters, setFilters] = useState<IDynamicFilterData>({
    type: 'editado',
    term: '',
    dateStart: '2023-01-01',
    dateEnd: '2023-12-31',
  });

  const [pagination, setPagination] = useState({
    current: 1,
    size: 20,
  });

  const totalPages = Math.ceil(metadata.totalItems / pagination.size);

  useEffect(() => {
    returnAllUsersLogs({
      type: filters.type,
      term: filters.term,
      currentPage: pagination.current,
      pageSize: pagination.size,
      dateStart: filters.dateStart,
      dateEnd: filters.dateEnd,
    });
  }, [filters, pagination.current]);

  const paginationSearch = (index: number) => {
    if (index > 0 && index <= totalPages) {
      window.scrollTo(0, 0);
      setPagination({
        current: index,
        size: pagination.size,
      });
    }
  };

  const handleOpenDetails = (elm: any) => {
    setActiveUser(elm);
    setOpenDetailsModal(true);
  };

  const handleSearch = (formData: IDynamicFilterData) => {
    setFilters(formData);
    returnAllUsersLogs({
      type: formData.type,
      term: formData.term,
      currentPage: 1,
      pageSize: pagination.size,
      dateStart: formData.dateStart,
      dateEnd: formData.dateEnd,
    });
  };

  const returnUserLog = (elm: any) => {
    if (!elm) return '-';

    const {
      ativo,
      usuarioEditado,
      usuarioEdicao,
      areaAtuacao,
      gruposEconomicos,
    } = elm;

    if (!ativo)
      return (
        <S.Text>
          Usuário <S.Text className="bold inline">{usuarioEditado}</S.Text> foi
          desativado por{' '}
          <S.Text className="bold inline">{usuarioEdicao}</S.Text>.
        </S.Text>
      );

    if (ativo && !areaAtuacao && !gruposEconomicos)
      return (
        <S.Text>
          Usuário <S.Text className="bold inline">{usuarioEditado}</S.Text> foi
          ativado.
        </S.Text>
      );

    const logs = [];

    if (areaAtuacao && !gruposEconomicos) {
      logs.push(
        <S.Text key="area-atuacao">
          <S.Text className="bold inline">Área de atuação</S.Text> editada para{' '}
          <S.Text className="blue bold inline">
            {mapNumbersToString(areaAtuacao)}.
          </S.Text>
        </S.Text>,
      );
    }

    if (gruposEconomicos && !areaAtuacao) {
      logs.push(
        <S.Text key="grupos-economicos">
          <S.Text className="bold inline">Grupo ecônomico</S.Text> editado para{' '}
          <S.Text className="blue bold inline">
            {mapNumbersToString(gruposEconomicos)}.
          </S.Text>
        </S.Text>,
      );
    }

    if (gruposEconomicos && areaAtuacao) {
      logs.push(
        <S.Text key="grupos-economicos">
          <S.Text className="bold inline">Grupo ecônomico</S.Text> editado para{' '}
          <S.Text className="blue bold inline">
            {mapNumbersToString(gruposEconomicos)}.
          </S.Text>
          <br />
          <S.Text className="bold inline">Área de atuação</S.Text> editada para{' '}
          <S.Text className="blue bold inline">
            {mapNumbersToString(areaAtuacao)}.
          </S.Text>
        </S.Text>,
      );
    }
    return logs.length > 0 ? logs : '-';
  };

  const filterSelectOptions: IDynamicFilterSelectOptions[] = [
    { value: 'editado', description: 'Usuário editado' },
    { value: 'edicao', description: 'Usuário edição' },
  ];

  return (
    <S.Container>
      <S.Content>
        <DynamicFilter
          filterSelectOptions={filterSelectOptions}
          filters={filters}
          calendar
          isLoading={isLoading}
          setFilters={setFilters}
          searchFilters={handleSearch}
        />
        <S.Tabs.TabsContainer>
          {isLoading && <Loading />}
          {!isLoading && (
            <S.Tabs.TabHeader>
              <S.Tabs.TabCell>Usuário editado</S.Tabs.TabCell>
              <S.Tabs.TabCell>Editado por</S.Tabs.TabCell>
              <S.Tabs.TabCell>Data da Edição</S.Tabs.TabCell>
              <S.Tabs.TabCell className="expanded-xl">
                Ação realizada
              </S.Tabs.TabCell>
            </S.Tabs.TabHeader>
          )}
          {usersLogs.map((elm: any) => {
            return (
              <S.Tabs.TabBody key={elm.id}>
                <S.Tabs.TabCell>{elm.usuarioEditado ?? '-'}</S.Tabs.TabCell>
                <S.Tabs.TabCell>{elm.usuarioEdicao ?? '-'}</S.Tabs.TabCell>
                <S.Tabs.TabCell>
                  {formatTimeStampToDate(elm.data)}
                </S.Tabs.TabCell>
                <S.Tabs.TabCell className="expanded-xl">
                  {returnUserLog(elm ?? '-')}
                </S.Tabs.TabCell>
              </S.Tabs.TabBody>
            );
          })}
        </S.Tabs.TabsContainer>

        <S.ListContainer>
          {isLoading && <Loading />}
          {usersLogs.map((elm: any) => {
            return (
              <LogListItem key={elm.id} log={elm} type="user">
                <S.IconButton
                  className={'edit'}
                  onClick={() => handleOpenDetails(elm)}
                >
                  <img src={Details} width={16} />
                  Detalhes
                </S.IconButton>
              </LogListItem>
            );
          })}
        </S.ListContainer>

        {!isLoading && usersLogs.length === 0 && (
          <S.Message>Nenhum resultado encontrado.</S.Message>
        )}

        {!isLoading && usersLogs.length !== 0 && (
          <Pagination
            startWithZero={false}
            currentPage={pagination.current}
            paginationSize={pagination.size}
            paginationSearch={paginationSearch}
            totalPages={totalPages}
          />
        )}
      </S.Content>

      {/* Modal */}
      <>
        <ModalResponsive
          title={'Detalhes de Log'}
          isOpenModal={OpenDetailsModal}
          handleOpenModal={setOpenDetailsModal}
        >
          <S.Featured>
            <S.Text>{returnUserLog(activeUser)}</S.Text>
          </S.Featured>
          <S.Buttons>
            <S.IconButton
              className="close"
              onClick={() => setOpenDetailsModal(false)}
            >
              Fechar
            </S.IconButton>
          </S.Buttons>
        </ModalResponsive>
      </>

      <ToastContainer
        closeButton={false}
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </S.Container>
  );
};
