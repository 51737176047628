import styled from 'styled-components';

export const ContainerFluid = styled.div`
  align-items: center;
  background-color: #133d6d;
  display: flex;
  justify-content: center;
  max-height: 43px;
  width: 100%;
  padding: 24px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1380px;
`;

export const Content = styled(Container)``;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const Icon = styled.img``;

export const Text = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;

  span {
    font-weight: 600;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }

  @media (min-width: 320px) {
    font-size: 12px;
    line-height: 24px;
  }

  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 16px;
  }
`;
