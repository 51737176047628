import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import * as S from './styles';
import {
  Topbar,
  Navbar,
  DashboardFooter,
  Breadcrumb,
} from '../../../components';
import { useNavigate } from 'react-router-dom';
import { Logs } from '../../../pages/logs';
import { UsersLogs } from '../../../pages/users-logs';
import { ProfileLogs } from '../../../pages/profile-logs';

const TABS = {
  CONTEXT: 'context',
  USERS_LOGS: 'users-logs',
  PERFIS_LOGS: 'profile-logs',
};

const PATHS = {
  PANEL: '/painel',
  LOGS_QUERY: '/logs/consulta',
  LOGS_USERS: '/logs/usuarios',
  LOGS_PERFIS: '/logs/perfis',
};

export const LogsPanel: React.FC = () => {
  const [activeTab, setActiveTab] = useState(TABS.CONTEXT);
  const [paths, setPaths] = useState([
    {
      title: 'Meu Painel',
      link: PATHS.PANEL,
    },
    {
      title: 'Consulta de Logs',
      link: PATHS.LOGS_QUERY,
    },
  ]);

  const navigate = useNavigate();

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
    if (tabName === TABS.CONTEXT) {
      navigate(PATHS.LOGS_QUERY);
    }
    if (tabName === TABS.USERS_LOGS) {
      navigate(PATHS.LOGS_USERS);
    }
    if (tabName === TABS.PERFIS_LOGS) {
      navigate(PATHS.LOGS_PERFIS);
    }
  };

  return (
    <S.Container>
      <Topbar />
      <Navbar />
      <S.Content className="modify-content">
        <Breadcrumb paths={paths} />
        <S.Title style={{ marginBottom: '3%' }}>Consulta de Logs</S.Title>

        <S.Tabs.TabsNavigation>
          <S.Tabs.TabsSelector className="secondary">
            <S.Tabs.TabSelector
              className={
                activeTab === TABS.CONTEXT ? 'active expanded' : 'expanded'
              }
              onClick={() => handleTabClick(TABS.CONTEXT)}
            >
              Contexto (PriceTableId)
            </S.Tabs.TabSelector>
            <S.Tabs.TabSelector
              className={activeTab === TABS.USERS_LOGS ? 'active' : ''}
              onClick={() => handleTabClick(TABS.USERS_LOGS)}
            >
              Usuários
            </S.Tabs.TabSelector>
            <S.Tabs.TabSelector
              className={activeTab === TABS.PERFIS_LOGS ? 'active' : ''}
              onClick={() => handleTabClick(TABS.PERFIS_LOGS)}
            >
              Perfis
            </S.Tabs.TabSelector>
          </S.Tabs.TabsSelector>
        </S.Tabs.TabsNavigation>
      </S.Content>

      {activeTab === TABS.CONTEXT && <Logs />}
      {activeTab === TABS.USERS_LOGS && <UsersLogs />}
      {activeTab === TABS.PERFIS_LOGS && <ProfileLogs />}

      <DashboardFooter />
    </S.Container>
  );
};
