import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Slide, ToastContainer } from 'react-toastify';
import Router from './routes';
import { GlobalStyle } from './styles/globalstyles';
import { BrowserRouter } from 'react-router-dom';
import 'react-responsive-modal/styles.css';
import { loadGoogleTagManager } from './services/gtm/gtmService';
import ModalComponent from './components/dynamic-modal';

export const App: React.FC = () => {
  const [redirectDomain, setRedirectDomain] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const currentDomain = window.location.hostname;

    if (currentDomain === 'portalallied.com.br') {
      setRedirectDomain('https://autoservico.alliedempresas.com.br/');
      setShowModal(true);
    } else if (currentDomain === 'homologacao.portalallied.com.br') {
      setRedirectDomain('http://hmg-allied-empresas.alliedtech.com.br/');
      setShowModal(true);
    }
  }, []);

  const handleRedirect = () => {
    window.location.href = `${redirectDomain}`;
  };

  return (
    <BrowserRouter>
      <GlobalStyle />
      <Router />
      <ToastContainer
        closeButton={false}
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {showModal && <ModalComponent onRedirect={handleRedirect} />}
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<App />);

loadGoogleTagManager();
