import React from 'react';
import * as S from './styles';

interface IDashboardItem {
  title: string;
  icon: string;
  path: string;
  isActive: boolean;
}

export const DashboardItem: React.FC<IDashboardItem> = ({
  title,
  icon,
  path,
  isActive,
}) => {
  return (
    <S.Content
      href={isActive ? path : '#'}
      className={isActive ? '' : 'inactive'}
    >
      <S.Image
        src={require(`../../assets/dashboard-icons/${icon}.png`)}
        alt={`${icon}`}
      />
      <S.Text>{title}</S.Text>
    </S.Content>
  );
};
