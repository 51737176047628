import React, { useState } from 'react';
import * as S from './styles';
import ArrowDown from '../../assets/arrow-down-order.png';
import { formatTimeStampToDate, returnUserLog } from '../../utils/functions';

interface ILogs {
  children: React.ReactNode;
  type: 'pricetable' | 'user' | 'profile';
  log: any;
}

export const LogListItem: React.FC<ILogs> = ({ children, log, type }) => {
  const [isActive, setIsActive] = useState(false);

  if (type === 'pricetable') {
    return (
      <S.ListItem>
        <S.ListHeader onClick={() => setIsActive(!isActive)}>
          <span className="bold">{log.contexto || '-'}</span> <br />
          Contexto (PriceTableId)
          <S.Arrow src={ArrowDown} className={isActive ? 'active' : ''} />
        </S.ListHeader>
        <S.ListBody className={isActive ? 'active' : ''}>
          <p>
            <span className="bold">{log.ean || '-'}</span>
            <br />
            EAN
          </p>
          <p>
            <span className="bold">
              {log.dataRegistro.replace(' ', ' às ') || '-'}
            </span>
            <br />
            Data do registro
          </p>
          <p>
            <span className="bold">{log.error || '-'}</span>
            <br />
            Tipo de erro
          </p>
          {children}
        </S.ListBody>
      </S.ListItem>
    );
  }

  if (type === 'user') {
    return (
      <S.ListItem>
        <S.ListHeader onClick={() => setIsActive(!isActive)}>
          <span className="bold">{log.usuarioEditado || '-'}</span>
          <br />
          Usuário editado
          <S.Arrow src={ArrowDown} className={isActive ? 'active' : ''} />
        </S.ListHeader>
        <S.ListBody className={isActive ? 'active' : ''}>
          <p>
            <span className="bold">{log.usuarioEdicao || '-'}</span>
            <br />
            Editado por
          </p>
          <p>
            <span className="bold">{formatTimeStampToDate(log.data)}</span>
            <br />
            Data da Edição
          </p>
          <p>
            <span className="bold">{returnUserLog(log)}</span> <br />
            <br />
            Ação realizada
          </p>
          {children}
        </S.ListBody>
      </S.ListItem>
    );
  }

  return (
    <S.ListItem>
      <S.ListHeader onClick={() => setIsActive(!isActive)}>
        <span className="bold">{log.nomePerfilAlteracao || '-'}</span>
        <br />
        Perfil editado
        <S.Arrow src={ArrowDown} className={isActive ? 'active' : ''} />
      </S.ListHeader>
      <S.ListBody className={isActive ? 'active' : ''}>
        <p>
          <span className="bold">{log.nomeUsuarioAlteracao || '-'}</span>
          <br />
          Editado por
        </p>
        <p>
          <span className="bold">
            {formatTimeStampToDate(log.dataAlteracao)}
          </span>
          <br />
          Data da Edição
        </p>
        <p>
          <span className="bold">
            {log.nomePermissaoAlterada} foi{' '}
            {log.tipoAlteracao ? 'habilitado' : 'desabilitado'}
          </span>
          <br />
          Permissão alterada
        </p>
        {children}
      </S.ListBody>
    </S.ListItem>
  );
};
