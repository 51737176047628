import styled from 'styled-components';

export const Button = styled.button`
  background-color: #f7941d;
  border-radius: 80px;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  height: 48px;
  line-height: 18px;
  text-align: center;
  width: 100%;
  transition: ease 0.2s;

  &:hover {
    background-color: #f7941dee;
    color: #fff;
  }

  &.secondary {
    background-color: transparent;
    border: 2px solid #f7941d;
    color: #f7941d;

    &:hover {
      background-color: #f7941dee;
      color: #fff;
    }
  }

  &.inactive {
    opacity: 0.4;
    cursor: not-allowed;
    &:hover {
      box-shadow: 0px 2px 4px 0px #033b7133;
    }
  }

  &.transparent {
    background-color: transparent;
    border: none;
    color: #f7941d;

    &:hover {
      background-color: #f7941dee;
      color: #fff;
    }
  }
`;
