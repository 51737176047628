import React from 'react';
import PrivateRoute from './PrivateRoute';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Login } from '../pages/login';
import { ResetPassword } from '../pages/reset-password';
import { ForgotPassword } from '../pages/forgot-password';
import { Dashboard } from '../pages/dashboard';
import { Orders } from '../pages/orders';
import { SendDanfeXML } from '../pages/send-danfe-xml';
import { OrderDetail } from '../pages/order-detail';
import { Profile } from '../pages/profile';
import { Boletos } from '../pages/boletos';
import { FirstRegister } from '../pages/first-register';
import { Users } from '../pages/users';
import { Logs } from '../pages/logs';
import { UsersLogs } from '../pages/users-logs';
import { Permissions } from '../pages/permissions';
import { Access } from '../models/components/access-control';
import { LogsPanel } from '../models/components/logs-panel';
import { ProfileLogs } from '../pages/profile-logs';
import { Comissionamento } from '../pages/Commissioning/Commissioning';

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/esqueci-a-senha" element={<ForgotPassword />} />
      <Route path="/resetar-senha" element={<ResetPassword />} />
      <Route path="/primeiro-cadastro" element={<FirstRegister />} />
      <Route path="*" element={<Navigate replace to="/" />} />
      <Route element={<PrivateRoute />} path="/controle-de-acesso">
        <Route element={<Access />}>
          <Route
            path="usuarios"
            element={<Users setTotalEmAnalise={() => null} />}
          />
          <Route path="perfis" element={<Permissions />} />
        </Route>
      </Route>
      <Route element={<PrivateRoute />} path="/logs">
        <Route element={<LogsPanel />}>
          <Route path="consulta" element={<Logs />} />
          <Route path="usuarios" element={<UsersLogs />} />
          <Route path="perfis" element={<ProfileLogs />} />
        </Route>
      </Route>
      <Route element={<PrivateRoute />} path="/logs">
        <Route element={<LogsPanel />}>
          <Route path="usuarios" element={<Logs />} />
        </Route>
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path="/painel" element={<Dashboard />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path="/pedidos" element={<Orders />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path="/envio-danfe-xml" element={<SendDanfeXML />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path="/pedido/:id" element={<OrderDetail />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path="/perfil" element={<Profile />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path="/boletos" element={<Boletos />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path="/comissionamento" element={<Comissionamento />} />
      </Route>
    </Routes>
  );
}

export default Router;
