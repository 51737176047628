import * as S from './styles';
import React, { Dispatch, SetStateAction } from 'react';
import { Modal } from 'react-responsive-modal';
import CloseIconSVG from '../../assets/buttons/close.svg';

interface IModalProps {
  title: string;
  children: React.ReactNode;
  isOpenModal: boolean;
  handleOpenModal: Dispatch<SetStateAction<boolean>>;
  isSmall?: boolean;
}

const CloseIcon = <img src={CloseIconSVG} width={32} />;

export const ModalResponsive: React.FC<IModalProps> = ({
  title,
  children,
  isOpenModal,
  handleOpenModal,
  isSmall = false,
}) => {
  const closeModal = () => {
    handleOpenModal(false);
  };

  return (
    <>
      {isOpenModal && (
        <Modal
          open={isOpenModal}
          onClose={closeModal}
          center
          closeIcon={CloseIcon}
          classNames={{
            overlay: 'customOverlay',
            modal: 'customModal ' + (isSmall && ' small-modal'),
          }}
        >
          <S.ModalHeader>
            <S.ModalTitle>{title}</S.ModalTitle>
          </S.ModalHeader>
          <S.ModalBody>{children}</S.ModalBody>
        </Modal>
      )}
    </>
  );
};
