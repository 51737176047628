import styled from 'styled-components';

export const OrderHistory = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProgressOrder = styled.div`
  margin: 70px 0 90px;
  position: relative;
  width: 100%;
  max-width: 950px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Step = styled.div`
  position: relative;
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 50%;
  transition: background 1s;
  background: white;
  border: 2px solid #f7941d;
  z-index: 999;

  &.complete {
    background: #f7941d;
  }

  &.canceled {
    background: #ff3333 !important;
    border: 2px solid #ff3333;
  }
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 47%;
  left: 0;
  width: 98%;
  height: 2px;
  background: #f7941d;
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: 400;

  span {
    font-weight: 700;
  }

  &.font-medium {
    font-weight: 600;
  }

  &.font-small {
    font-size: 12px;
  }

  &.gray {
    color: #777777;
  }
`;

export const FloatText = styled(Text)`
  font-size: 12px;
  position: absolute;
  width: 80px;
  top: 35px;
  left: 50%;
  transform: translate(-50%, 0%);
  text-align: center;
`;
