import React from 'react';
import * as S from './styles';
import AlliedLogo from '../../assets/allied-empresas-logo.png';
import { Topbar, LoginFooter } from '../../components';
import { useAuth } from '../../hooks';
import { SubmitHandler, useForm } from 'react-hook-form';

interface IUserData {
  email: string;
}

export const ForgotPassword: React.FC = () => {
  const { isLoading, isSent, apiErrors, forgotPass } = useAuth();

  // Hook do Formulário
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserData>();

  // Montagem e Consumo do Endpoit para LOGIN
  const onSubmit: SubmitHandler<IUserData> = async user => {
    const values = {
      email: user.email,
    };
    await forgotPass(values);
  };

  return (
    <>
      <Topbar />
      <S.Container>
        <S.Image src={AlliedLogo} />
        {isSent ? (
          <>
            <S.Title>E-mail enviado!</S.Title>
            <S.Text>
              Verifique sua caixa de entrada e clique no link para continuar a
              redefinição de senha
            </S.Text>
          </>
        ) : (
          <>
            <S.Text>
              Digite seu e-mail e iremos enviar um link para redefinição de
              senha
            </S.Text>
            <S.Form onSubmit={handleSubmit(onSubmit)}>
              <S.Input
                type="text"
                id="email"
                placeholder="E-mail cadastrado"
                {...register('email', {
                  required: true,
                })}
              />
              {errors.email && errors.email.type === 'required' && (
                <S.ErrorText className="required-message">
                  E-mail obrigatório
                </S.ErrorText>
              )}
              {apiErrors &&
                apiErrors.map((element, index) => {
                  return (
                    <S.ErrorText key={index} className="required-message">
                      {element}
                    </S.ErrorText>
                  );
                })}
              <S.Button className={isLoading ? 'loading' : ''} type="submit">
                {isLoading ? 'Enviando...' : 'Enviar link para e-mail'}
              </S.Button>
            </S.Form>
          </>
        )}
      </S.Container>
      <LoginFooter />
    </>
  );
};
