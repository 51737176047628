import styled from 'styled-components';
import * as c from '../palette';

const SecondaryTabsSelector = `
  border-bottom: 1px solid ${c.gray};
  color: ${c.gray};
  * {
    text-align: center;
    background-color: transparent;
    font-size: 16px;
    width: 140px;
    padding: 12px;
  }
  .expanded {
    width: 240px;
  }
  .active {
    border: none;
    border-bottom: 2px solid ${c.orange};
    font-weight: 600;
    color: ${c.orange};
  }
`;

export const TabsNavigation = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`;

export const TabsSelector = styled.div`
  display: flex;
  justify-content: start;
  gap: 8px;

  &.secondary {
    ${SecondaryTabsSelector}
  }
`;

export const TabSelector = styled.div`
  cursor: pointer;
  font-size: 14px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: ${c.lightGray};
  width: 190px;
  padding: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  :hover {
    opacity: 0.8;
  }

  &.loading-tab {
    pointer-events: none;
    cursor: not-allowed;
  }

  &.active {
    background-color: white;
    border: 1px solid ${c.lightGray};
    font-weight: 700;
    border-bottom: 2px solid ${c.orange};
  }
`;

export const TabsContent = styled.table`
  font-size: 14px;
  width: 100%;
  margin: 32px 0 0;
  border-collapse: collapse;
`;

export const TabsRow = styled.tr`
  &:nth-child(even) {
    background-color: ${c.lightSecondary};
  }
`;

export const TabsHead = styled.th`
  cursor: pointer;
  padding: 16px;
  transition: 0.2s ease;

  &.active {
    color: ${c.orange};
  }

  :hover {
    color: ${c.orange};
  }
`;

export const TabArrow = styled.img`
  margin: 0 0 2px 5px;
`;

export const TabsData = styled.td`
  cursor: pointer;
  text-align: center;
  padding: 16px;

  &.start {
    text-align: start;
  }
`;

export const TabDownload = styled.img`
  cursor: pointer;
  height: 16px;
  width: 16px;
`;

export const TabsContainer = styled.div`
  margin: 40px 0px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  font-size: 13px;

  @media (max-width: 1280px) {
    max-width: 992px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const TabHeader = styled.div`
  background-color: ${c.light};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${c.graySecondary};
  font-weight: 700;
`;

export const TabBody = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${c.graySecondary};
  transition: 0.2s ease all;

  &:hover {
    background-color: ${c.light};
  }
`;

export const TabCell = styled.div`
  width: 100%;
  max-width: 200px;
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;

  &.expanded {
    word-break: break-word;
    min-width: 230px;
    max-width: 260px;
    padding: 18px;
    gap: 3px;
  }

  &.smaller {
    word-break: break-word;
    max-width: 150px;
  }

  &.expanded-lg {
    word-break: break-word;
    max-width: 350px;
  }

  &.expanded-xl {
    word-break: break-word;
    max-width: 450px;
  }

  &.smaller {
    word-break: break-word;
    max-width: 150px;
  }

  &.expanded-lg {
    word-break: break-word;
    max-width: 350px;
  }

  &.smaller {
    word-break: break-word;
    max-width: 150px;
  }

  &.expanded-lg {
    word-break: break-word;
    max-width: 350px;
  }

  &.expanded-xl {
    word-break: break-word;
    max-width: 450px;
  }

  &.smaller {
    word-break: break-word;
    max-width: 150px;
  }

  &.expanded-lg {
    word-break: break-word;
    max-width: 350px;
  }

  &.expanded-xl {
    word-break: break-word;
    max-width: 450px;
  }
`;

export const TabBadge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 24px;
  max-height: 24px;
  border-radius: 8px;
  color: white;
  font-size: 12px !important;
  background-color: ${c.orange} !important;

  &.inactive {
    background-color: ${c.textGray} !important;
  }
`;
