import axios from 'axios';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  IChangePassword,
  IForgotPassword,
  IResetPassword,
  ISignIn,
  IUserData,
} from '../../interfaces';
import { dealerProvider, identityProvider } from '../../services/api';
import { generateToken } from '../../services/token';
import { useStorage } from '../useStorage';

export const useAuth = () => {
  const { setItemOnStorage, getItemOnStorage } = useStorage();

  const [searchParams] = useSearchParams();
  const clientId = searchParams.get('clientId');
  const redirect_uri = searchParams.get('redirect_uri');
  const state = searchParams.get('state');
  const user_email = searchParams.get('user_email');
  const reset_token = searchParams.get('reset_token');

  const [isLoading, setIsLoading] = useState(false);
  const [hasLoginEerror, setHasLoginEerror] = useState('');
  const [apiErrors, setApiErrors] = useState([]);
  const [response, setResponse] = useState<any>(null);
  const [isSent, setIsSent] = useState(false);

  const isAuthenticated = getItemOnStorage('token');
  const callback_url = getItemOnStorage('callback_url');
  const defaultClientID = '5a1966f44b26c1785778a7e0a2c372bc';
  const defaulUserID = '5a1966f44b26c1785778a7e0a2c372bc';
  const defaulUserSecret =
    'yKpyRnv4H%Ky7mKWfsCmkXbYUaFhbHAxS2PPLnwUz6D6nS!nqQJiKSms5G3^^5YR';

  const signIn = async (values: ISignIn) => {
    const { email, password } = values;
    setIsLoading(true);

    try {
      const response = await identityProvider.post(
        `/api/getAuthorizationCode`,
        {
          state: state,
          redirect_Uri: redirect_uri,
          client_id: clientId ?? defaultClientID,
          email: email,
          password: password,
        },
      );

      if (redirect_uri) {
        redirectToVTEX(redirect_uri, response.data.state, response.data.code);
        return true;
      }
      getAccessCode(response.data.code);
      return response;
    } catch (err: any) {
      setIsLoading(false);
      return err.response;
    }
  };

  const getAccessCode = async (token: string) => {
    try {
      const response = await identityProvider.post(`/api/getAccessCode`, {
        authorizationCode: token,
        client_Id: defaulUserID,
        client_Secret: defaulUserSecret,
        redirect_Uri:
          'https://vtexid.vtex.com.br/VtexIdAuthSiteKnockout/ReceiveAuthorizationCode.ashx',
      });
      getUserInfo(response.data.accessToken);
      setItemOnStorage('token', response.data.accessToken);
    } catch (err: any) {
      setIsLoading(false);
      setHasLoginEerror(err.response.data.error);
    }
  };

  const getUserInfo = async (token: string) => {
    try {
      const response = await identityProvider.get(`/api/getUserInfo`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      setItemOnStorage('currentUser', JSON.stringify(response.data));
      if (callback_url) {
        window.location.replace(callback_url);
      } else {
        window.location.replace('/painel');
      }
    } catch (err: any) {
      return err.response.data.error;
    } finally {
      setIsLoading(false);
    }
  };

  const getUserData = async (values: IUserData) => {
    const { email } = values;

    try {
      const response = await axios.get(
        `https://empresas.alliedtech.com.br/identityprovider/api/masterdata?email=${email}`,
      );

      const userData = response.data;

      setItemOnStorage('vendorData', JSON.stringify(userData));

      const vendorCode = userData.data.vendedorAdicional;
      const vendorEmail = userData.data.email;
      const vendorFirstName = userData.data.firstName;

      setItemOnStorage('vendorCode', vendorCode ? vendorCode : '');
      setItemOnStorage('vendorEmail', vendorEmail);
      setItemOnStorage('vendorFirstName', vendorFirstName);
    } catch (err) {
      return err;
    }
  };

  const redirectToVTEX = async (
    redirectUri: string,
    state: string,
    authorizationCode: string,
  ) => {
    const redirectUrl =
      redirectUri + `?state=${state}&authorizationCode=${authorizationCode}`;
    window.location.replace(redirectUrl);
  };

  const forgotPass = async (values: IForgotPassword) => {
    const { email } = values;
    setIsLoading(true);
    setResponse(null);
    setApiErrors([]);

    await dealerProvider
      .post(
        `/api/atualizar`,
        {
          email: email,
          atualsenha: '',
          novasenha: '',
          pararedefinir: true,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(response => {
        setResponse(response.data.data);
        setIsSent(true);
      })
      .catch(err => {
        setIsSent(false);
        setApiErrors(err.response.data.errors);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetPass = async (values: IResetPassword) => {
    const { password } = values;
    setIsLoading(true);
    setResponse(null);
    setApiErrors([]);

    try {
      const responseData = await dealerProvider.post(
        `/api/atualizar`,
        {
          email: user_email,
          atualsenha: reset_token,
          novasenha: password,
          pararedefinir: false,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      return responseData;
    } catch (err: any) {
      setApiErrors(err.response.data.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const changePass = async (values: IChangePassword) => {
    const { oldPassword, password } = values;
    setIsLoading(true);

    const user = getItemOnStorage('currentUser');
    const userData = JSON.parse(user || `{}`);

    try {
      const response = await dealerProvider.post(
        `/api/atualizar`,
        {
          email: userData.userEmail ?? '',
          atualsenha: oldPassword,
          novasenha: password,
          pararedefinir: false,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (err: any) {
      return err.response.data.errors;
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    isLoading,
    isAuthenticated,
    hasLoginEerror,
    isSent,
    apiErrors,
    response,
    signIn,
    forgotPass,
    resetPass,
    changePass,
    getUserData,
  };

  return value;
};
