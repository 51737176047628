import styled from 'styled-components';

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: end;
`;

export const Text = styled.p`
  margin: 2px 16px 0;
  font-size: 14px;

  @media (max-width: 768px) {
    margin: 2px 4px 0;
    font-size: 12px;
  }
`;

export const Pagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

export const PagItem = styled.button`
  background: transparent;
  transition: 0.2s ease all;
  padding: 10px;
  border-radius: 50px;
  cursor: pointer;
  width: 35px;
  height: 35px;

  @media (max-width: 768px) {
    margin: 2px 4px 0;
    font-size: 10px;
    width: 15px;
    height: 15px;
  }

  &:hover {
    color: #f7941d;
  }

  &.is-selected {
    font-weight: bold;
    color: #f7941d;
  }

  &.is-disabled {
    pointer-events: none;
    opacity: 0.7;
  }
`;

export const PagPrevious = styled(PagItem)``;

export const PagNext = styled(PagItem)``;
