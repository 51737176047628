import styled from 'styled-components';
import * as Tabs from '../../../styles/lib/tabs';
import ReactSelect from 'react-select';

export { Tabs };

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

export const Content = styled.div`
  padding: 40px 20px;
  width: 100%;
  max-width: 1268px;
  min-height: 670px;

  @media (max-width: 1280px) {
    max-width: 1000px;
  }

  @media (max-width: 1024px) {
    max-width: 768px;
  }

  &.modify-content {
    padding: 40px 20px 0px 20px !important;
    min-height: 0px;
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  color: rgb(3, 59, 113);

  @media (max-width: 766px) {
    font-size: 18px;
  }
`;
