import React from 'react';
import * as S from './styles';

interface OrderProgressProps {
  orderHistory: StepOrderProps[];
  finalStatus?: string;
}

interface StepOrderProps {
  label: string;
  labelOrigin: string;
  dateTime: string;
}

export const OrderProgressBar: React.FC<OrderProgressProps> = ({
  orderHistory,
}) => {
  const orderSteps = orderHistory.map(e => {
    if (e.label) {
      return e.label;
    } else {
      return e.labelOrigin;
    }
  });

  while (orderSteps.length < fixedStepsStatus.length) {
    orderSteps.push('');
  }

  const orderStepsWithStatus = fixedStepsStatus.map((elm, idx) => {
    return {
      label: elm.label,
      complete: orderSteps.includes(elm.label),
      canceled: orderSteps.includes('CANC'),
    };
  });

  return (
    <S.OrderHistory>
      <S.ProgressOrder>
        {orderStepsWithStatus.map((elm, idx) => {
          return (
            <S.Step key={idx} className={elm.complete ? 'complete ' : ''}>
              <S.FloatText className={''}>{elm.label}</S.FloatText>
            </S.Step>
          );
        })}
        <S.ProgressBar />
      </S.ProgressOrder>
    </S.OrderHistory>
  );
};

const fixedStepsStatus = [
  {
    label: 'Pedido recebido',
    complete: false,
    canceled: false,
  },
  {
    label: 'Pagamento aprovado',
    complete: false,
    canceled: false,
  },
  {
    label: 'Nota fiscal disponível',
    complete: false,
    canceled: false,
  },
  {
    label: 'Pedido enviado',
    complete: false,
    canceled: false,
  },
  {
    label: 'Pedido entregue',
    complete: false,
    canceled: false,
  },
];
