import React, { useState } from 'react';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import { SubmitHandler, useForm } from 'react-hook-form';
import { EyeOff, EyeIcon } from 'lucide-react';

import { IChangePassword } from '../../interfaces';
import AlliedLogo from '../../assets/allied-empresas-logo.png';
import AlliedLogoHorizontal from '../../assets/allied-empresas-logo-horizontal.png';
import MenuMobile from '../../assets/menu-mobile.png';
import User from '../../assets/user.png';
import Cart from '../../assets/cart.png';
import { useAuth, useStorage } from '../../hooks';
import { capitalizeAllWords } from '../../utils/functions';

export const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const { getItemOnStorage, removeItemOnStorage } = useStorage();
  const { isLoading, changePass } = useAuth();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [error, setError] = useState<string>('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IChangePassword>();

  const onSubmit: SubmitHandler<IChangePassword> = async user => {
    const values = {
      oldPassword: user.oldPassword,
      password: user.password,
    };
    setError('');

    if (user.password !== user.confirmPassword) {
      setError('As senhas devem ser iguais.');
    }

    const response = await changePass(values);

    if (response.success) {
      onOpenModal();
    } else {
      setError(response);
    }
  };

  const onOpenModal = () => {
    setOpenModal(!openModal);
    setError('');
    setValue('oldPassword', '');
    setValue('confirmPassword', '');
    setValue('password', '');
  };

  function logOutTemporary() {
    removeItemOnStorage('token');
    navigate('/');
  }

  const user = getItemOnStorage('currentUser');
  const userData = JSON.parse(user || `{}`);

  const userName = userData.user ? `Olá, ${userData.user}` : `Boas vindas`;
  const userProfile = userData.perfil
    ? capitalizeAllWords(userData.perfil.replace('-', ' '))
    : 'Sem Acesso';

  return (
    <S.ContainerFluid>
      <S.Container>
        <S.Box>
          <a href="/painel">
            <S.Image className="allied" src={AlliedLogo} alt="Allied Logo" />
          </a>
        </S.Box>
        <S.Box>
          <S.BoxUser>
            <S.Dropdown>
              <S.DropdownButton>
                <S.Text>{userName}</S.Text>
                <S.TextSmall className="sub">{userProfile}</S.TextSmall>
              </S.DropdownButton>
              <S.DropdownContent className="show">
                <S.DropdownItem href={'/perfil'}>Meu Perfil</S.DropdownItem>
                <S.DropdownItem onClick={onOpenModal}>
                  Alterar Senha
                </S.DropdownItem>
                <S.DropdownItem onClick={logOutTemporary}>Sair</S.DropdownItem>
              </S.DropdownContent>
            </S.Dropdown>
          </S.BoxUser>
        </S.Box>
      </S.Container>
      <S.ContainerMobile>
        <S.Box>
          {/* <S.Image src={MenuMobile} alt="Menu Mobile Icon" /> */}
          <S.ImageMob src={AlliedLogo} alt="Allied Logo" />
        </S.Box>
        <S.Box>
          {/* <S.Image src={User} alt="User Icon" />
          <S.Image src={Cart} alt="Cart Icon" /> */}

          <S.BoxUser>
            <S.Dropdown>
              <S.DropdownButton>
                <S.Image src={User} alt="User Icon" />
              </S.DropdownButton>
              <S.DropdownContent className="show">
                <S.DropdownItem href={'/perfil'}>Meu Perfil</S.DropdownItem>
                <S.DropdownItem onClick={onOpenModal}>
                  Alterar Senha
                </S.DropdownItem>
                <S.DropdownItem onClick={logOutTemporary}>Sair</S.DropdownItem>
              </S.DropdownContent>
            </S.Dropdown>
          </S.BoxUser>
        </S.Box>
      </S.ContainerMobile>

      {openModal && (
        <Modal
          open={openModal}
          onClose={onOpenModal}
          center
          classNames={{
            overlay: 'customOverlay',
            modal: 'customModal',
          }}
        >
          <S.ModalHeader>
            <S.ImageHorizontal src={AlliedLogoHorizontal} alt="Allied Logo" />
          </S.ModalHeader>
          <S.ModalBody>
            <S.Title>Digite sua nova senha</S.Title>
            <S.Form onSubmit={handleSubmit(onSubmit)}>
              <S.PasswordWraper>
                <S.Input
                  type={showOldPassword ? 'text' : 'password'}
                  id="password"
                  placeholder="Senha atual"
                  {...register('oldPassword', {
                    required: true,
                  })}
                />
                <S.PasswordControl
                  onClick={() => setShowOldPassword(!showOldPassword)}
                >
                  {showOldPassword ? (
                    <EyeOff color="#7c7e80" size={20} strokeWidth={2} />
                  ) : (
                    <EyeIcon color="#7c7e80" size={20} strokeWidth={2} />
                  )}
                </S.PasswordControl>
              </S.PasswordWraper>

              <S.PasswordWraper>
                <S.Input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  placeholder="Nova senha"
                  {...register('password', {
                    required: true,
                  })}
                />
                <S.PasswordControl
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeOff color="#7c7e80" size={20} strokeWidth={2} />
                  ) : (
                    <EyeIcon color="#7c7e80" size={20} strokeWidth={2} />
                  )}
                </S.PasswordControl>
              </S.PasswordWraper>

              <S.PasswordWraper>
                <S.Input
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirm-password"
                  placeholder="Confirmar nova senha"
                  {...register('confirmPassword', {
                    required: true,
                  })}
                />
                <S.PasswordControl
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <EyeOff color="#7c7e80" size={20} strokeWidth={2} />
                  ) : (
                    <EyeIcon color="#7c7e80" size={20} strokeWidth={2} />
                  )}
                </S.PasswordControl>
              </S.PasswordWraper>

              {error ? <S.TextError>{error}</S.TextError> : <></>}
              {errors.password &&
                errors.oldPassword &&
                errors.password.type === 'required' &&
                errors.oldPassword.type === 'required' && (
                  <S.TextError className="required-message">
                    Senha obrigatória
                  </S.TextError>
                )}

              <S.TextWrapper>
                <ul>
                  * A senha deve conter:
                  <li>No mínimo 8 caracteres;</li>
                  <li>Letra maiúscula;</li>
                  <li>Letra minúscula;</li>
                  <li>Número;</li>
                  <li>Caractere especial (#, @, !, ...).</li>
                </ul>
              </S.TextWrapper>
              <S.ButtonWithLoading
                className={isLoading ? 'loading' : ''}
                type="submit"
              >
                {isLoading ? 'Salvando...' : 'Confirmar'}
              </S.ButtonWithLoading>
            </S.Form>
          </S.ModalBody>
        </Modal>
      )}
    </S.ContainerFluid>
  );
};
