// CollapseList.tsx
import React, { useState } from 'react';
import * as Collapse from '../../styles/lib/collapse';
import * as Buttons from '../../styles/lib/buttons';
import Edit from '../../assets/buttons/edit.svg';
import Trash from '../../assets/buttons/trash.svg';
import Close from '../../assets/icons/close_clean.svg';
import Check from '../../assets/icons/check_clean.svg';
import Person from '../../assets/icons/person.svg';
import { Utils } from '../../utils/functions';
import { ParallelList } from '../../styles/lib/lists';
import { CollapseListProps } from '../../interfaces';
import { format } from 'date-fns';

const CollapseList: React.FC<CollapseListProps> = ({
  id,
  nome,
  qtdUsuarios,
  ativo,
  permissionsProfile,
  permissoes,
  log,
  onEditButtonClick,
  onDeactivateButtonClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const handleToggleStatusClick = () => {
    onDeactivateButtonClick({
      id,
      nome,
      qtdUsuarios,
      ativo,
      permissionsProfile,
      permissoes,
      log,
    });
  };

  const arrowClass = isExpanded ? 'arrow-up' : 'arrow-down';

  const handleEditButtonClick = () => {
    onEditButtonClick({
      id,
      nome,
      qtdUsuarios,
      ativo,
      permissionsProfile,
      permissoes,
      log,
    });
  };

  return (
    <Collapse.List
      onClick={toggleExpansion}
      className={isExpanded ? 'active' : ''}
    >
      <Collapse.Content>
        <h4>
          {Utils.capitalizeFirstLetters(nome)}
          <span className="badge">
            <img src={Person} width={12} />
            {qtdUsuarios}
          </span>
        </h4>

        <div className="flex">
          <Buttons.IconButton
            className="edit"
            onClick={e => {
              e.stopPropagation();
              handleEditButtonClick();
            }}
          >
            <img src={Edit} width={16} />
            Editar
          </Buttons.IconButton>
          <Buttons.IconButton
            className={ativo ? 'deactivate' : 'reactivate'}
            onClick={e => {
              e.stopPropagation();
              handleToggleStatusClick();
            }}
          >
            <img src={ativo ? Trash : Check} width={16} />
            {ativo ? 'Desativar' : 'Reativar'}
          </Buttons.IconButton>
          <button className="collapse-button" onClick={toggleExpansion}>
            <img
              src={require(`../../assets/${arrowClass}.svg`)}
              alt={arrowClass}
              width={20}
              className={arrowClass}
            />
          </button>
        </div>
      </Collapse.Content>
      <Collapse.ItemContent className={isExpanded ? 'active' : ''}>
        <ParallelList>
          {permissionsProfile &&
            permissionsProfile.length > 0 &&
            permissionsProfile.map((item: any, index: any) => (
              <li key={index}>
                <img
                  src={permissoes.includes(item.area) ? Check : Close}
                  width={14}
                />
                {item.descricao}
              </li>
            ))}
        </ParallelList>
        {log.dataCriacaoLog !== null && (
          <div className="log-content">
            <p>Atualizado por: {log.usuarioAlteracaoPerfil}</p>
            <p>
              Atualizado em:{' '}
              {format(new Date(log.dataCriacaoLog), 'dd/MM/yyyy HH:mm')}
            </p>
          </div>
        )}
      </Collapse.ItemContent>
    </Collapse.List>
  );
};

export default CollapseList;
