import React from 'react';
import * as S from './styles';

interface IButton {
  title: string;
  type?: string;
}

export const Button: React.FC<IButton> = ({ title, type }) => {
  return (
    <S.Button className={type}>
      <p>{title}</p>
    </S.Button>
  );
};
