import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import * as S from '../styles';
import {
  FormPermissionsProps,
  IEditFormPermissions,
  IPermissionProfiles,
} from '../../../interfaces';
import { LoadingCircle } from '../../../components';
import styled from 'styled-components';

export const FormPermissions: React.FC<FormPermissionsProps> = ({
  permissions,
  profileGroup,
  onSave,
  onCancel,
  load,
}) => {
  const [formData, setFormData] = useState<IEditFormPermissions | any>();
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>(
    profileGroup?.permissoes || [],
  );
  const [titlePermission, setTitlePermission] = useState<string>(
    profileGroup?.nome || '',
  );

  useEffect(() => {
    setFormData({
      id: profileGroup?.id,
      nome: titlePermission,
      descricao: profileGroup?.nome,
      permissoes: profileGroup?.permissoes
        ? profileGroup?.permissoes.map((item: string) => {
            const permission = permissions.find(p => p.area === item);
            return {
              id: permission ? permission.id : 0,
              area: item,
            };
          }) || []
        : [],
    });
  }, [profileGroup, permissions]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isSelected = selectedPermissions.includes(value);
    setSelectedPermissions(prevPermissions => {
      if (isSelected) {
        return prevPermissions.filter(item => item !== value);
      } else {
        return [...prevPermissions, value];
      }
    });
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTitlePermission(value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const uniqueSelectedPermissions: any[] = [...new Set(selectedPermissions)];

    const updatedUserData = {
      ...formData,
      nome: titlePermission,
      descricao: titlePermission,
      permissoes: uniqueSelectedPermissions
        .map(area => permissions.find(p => p.area === area))
        .filter(permission => permission !== null)
        .map((permission: any) => ({
          id: permission.id,
          area: permission.area,
        })),
    };

    await onSave(updatedUserData);
  };

  const isSaveDisabled =
    load || !selectedPermissions.length || !titlePermission.trim();

  return (
    <S.FormPermission>
      <S.Text className="top-margin bold">Nome do perfil</S.Text>
      <S.Input.PrimaryInput
        type="text"
        value={titlePermission}
        onChange={handleTitleChange}
        id="nome"
        placeholder="Digite o nome do perfil de acesso"
      />

      <S.Text className="top-margin bold">Permissões</S.Text>

      <div className="form-control">
        {permissions.map((permission: IPermissionProfiles, index: number) => (
          <label key={index}>
            <input
              type="checkbox"
              name={permission.id.toString()}
              value={permission.area}
              checked={selectedPermissions.includes(permission.area)}
              onChange={handleInputChange}
            />
            <span>{permission.descricao}</span>
          </label>
        ))}
      </div>

      {!selectedPermissions.length && (
        <S.Text className="small-danger" style={{ paddingTop: '3%' }}>
          Selecione pelo menos uma permissão.
        </S.Text>
      )}

      <S.Buttons.Buttons className="top-margin">
        <S.Buttons.IconButton className="info" onClick={handleCancel}>
          Cancelar
        </S.Buttons.IconButton>
        <S.Buttons.IconButton
          className={`update ${isSaveDisabled ? 'disabled' : ''}`}
          onClick={handleSubmit}
          disabled={isSaveDisabled}
        >
          {load ? <LoadingCircle /> : 'Salvar'}
        </S.Buttons.IconButton>
      </S.Buttons.Buttons>
    </S.FormPermission>
  );
};
