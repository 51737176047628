import React from 'react';
import * as S from './styles';

export const LoadingCircle: React.FC = () => {
  return (
    <>
      <S.LoadingAnimated />
    </>
  );
};
