export const orange = '#f7941d';
export const lightOrange = '#fde4c7';
export const gray = '#58595b';
export const graySecondary = '#666';
export const light = '#f5f5f5';
export const lightGray = '#e3e4e6';
export const lightSecondary = '#f6f6f6';
export const textGray = '#7c7e80';
export const textPrimary = '#033B71';

export const filterColors = {
  primary: lightOrange,
  secondary: gray,
  border: '#e0e0e0',
  borderBold: '#222',
  background: '#f4f4f4',
  text: orange,
  disabledText: '#bdbdbd',
};
